import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import axios from 'axios'

import Categories from './Categories'
import ProductsSection from './ProductsSection'
import { parseError } from '../../../../lib/helpers'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
}))

const Products = ({ coupon: c }) => {
  // console.log(c)
  const classes = useStyles()

  const [products, setProducts] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    let didCancel = false
    if (!isLoading) return

    const getInitialData = async () => {
      setLoading(true)
      try {
        const response = await axios.get(`/fetchProducts`)
        if (didCancel) return
        setProducts(response.data)
      } catch (e) {
        if (!didCancel) {
          setError(`Load categories ${parseError(e)}`)
        }
      } finally {
        if (!didCancel) {
          setLoading(false)
        }
      }
    }

    getInitialData()

    return () => {
      didCancel = true
    }
  }, [c._embedded, isLoading])

  if (isLoading) return <CircularProgress />

  // console.log(products)
  // console.log(categories)

  const purchaseOptions = c.product_code_restrictions
    ? c.product_code_restrictions
        .split(',')
        .map(p => p.trim())
        .filter(p => p !== '')
    : []

  return (
    <Box mt={2}>
      {error && (
        <Box>
          <Typography gutterBottom color="error">
            {error}
          </Typography>
        </Box>
      )}
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <Paper elevation={3} className={classes.paper}>
            <ProductsSection products={products} purchaseOptions={purchaseOptions} coupon={c} />
          </Paper>
        </Grid>
        <Grid item sm={6}>
          <Paper elevation={3} className={classes.paper}>
            <Categories coupon={c} />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}

Products.propTypes = {
  coupon: PropTypes.object.isRequired,
}

export default Products
