import React from 'react'
// import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import MUILink from '@material-ui/core/Link'
import { Link } from 'react-router-dom'

import Layout from '../Layout'

const Reports = () => {
  return (
    <Layout title="Reports">
      <List>
        <ListItem>
          <MUILink component={Link} to="/reports/ichess">
            iChess Commissions Report
          </MUILink>
        </ListItem>
        <ListItem>
          <MUILink component={Link} to="/reports/commissions">
            Author Commissions Report
          </MUILink>
        </ListItem>
        <ListItem>
          <MUILink component={Link} to="/reports/killer-d4-ebook">
            Killer d4 eBook Commissions Report
          </MUILink>
        </ListItem>
        <ListItem>
          <MUILink component={Link} to="/reports/vat">
            VAT Report
          </MUILink>
        </ListItem>
        <ListItem>
          <MUILink component={Link} to="/reports/best-sellers">
            Best Sellers
          </MUILink>
        </ListItem>
        <ListItem>
          <MUILink component={Link} to="/reports/receipts">
            Customer Receipts
          </MUILink>
        </ListItem>
      </List>
    </Layout>
  )
}
export default Reports
