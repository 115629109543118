import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { green, red } from '@material-ui/core/colors'
import clsx from 'clsx'
import axios from 'axios'
import { withSnackbar } from 'notistack'
import isNaN from 'lodash/isNaN'

import AuthorSelect from '../../AuthorSelect'
import ProductSelect from '../../../../ProductSelect'
import PurchaseOptionSelect from '../../../../PurchaseOptionSelect'
import { GBP, parseError } from '../../../../../lib/helpers'

const useStyles = makeStyles(theme => {
  // console.log(theme)
  return {
    button: {
      // marginRight: theme.spacing(1),
    },
    caption: {
      fontSize: theme.typography.caption.fontSize,
    },
    green: {
      color: green[500],
    },
    red: {
      color: red[500],
    },
    divider: {
      margin: theme.spacing(1, 0),
    },
  }
})

const EditCommissionRow = ({
  authors,
  commission: c,
  products,
  setCommissionPercentages,
  year,
  setEditId,
  enqueueSnackbar,
}) => {
  const classes = useStyles()

  const [authorSlug, setAuthorSlug] = useState(c.authorSlug)
  const [isActive, setIsActive] = useState(c.isActive ? 'Active' : 'Not active')
  const [commissionType, setCommissionType] = useState(
    c.commissionPercentage ? 'percentage' : 'fixed'
  )
  const [amount, setAmount] = useState(
    (c.commissionPercentage ? c.commissionPercentage : c.commissionFixedGBP) || '0'
  )
  // console.log(amount)
  const [purchaseOption, setPurchaseOption] = useState(c.purchaseOption)

  const productObj = products.find(p => p.uid === purchaseOption)
  const productText = productObj ? productObj.data.product.data.title[0].text : purchaseOption
  const [product, setProduct] = useState(productText)

  const allPurchaseOptions = products.slice()
  const purchaseOptions = []
  if (product !== 'select') {
    allPurchaseOptions.forEach(p => {
      if (!p.data.product || !p.data.product.data) return
      if (p.data.product.data.title[0].text === product) {
        // console.log(p)
        purchaseOptions.push({ value: p.uid, label: `${p.data.description} ${GBP(p.data.price)}` })
      }
    })
  }

  const handleAuthorSlugChange = e => setAuthorSlug(e.target.value)
  const handleProductChange = e => {
    setProduct(e.target.value)
    setPurchaseOption('select')
  }
  const handlePurchaseOptionChange = e => setPurchaseOption(e.target.value)

  const isInvalid =
    authorSlug === 'select' || purchaseOption === 'select' || parseFloat(amount) <= 0

  const handleSave = async () => {
    if (isInvalid) return

    try {
      const response = await axios.get(
        `/commissionsReportSettings?action=update&id=${c._id}&year=${year}&authorSlug=${authorSlug}&purchaseOption=${purchaseOption}&type=${commissionType}&amount=${amount}&isActive=${isActive}`
      )
      // console.log(response.data)
      if (response.data.count !== 1) {
        enqueueSnackbar(`Unable to add commission.`)
      } else {
        const response = await axios.get(`/commissionsReportSettings?year=${year}`)
        setCommissionPercentages(response.data)
        enqueueSnackbar('Commission updated.', { variant: 'success' })
      }
    } catch (e) {
      enqueueSnackbar(parseError(e), { variant: 'error' })
    } finally {
      setEditId(null)
    }
  }

  return (
    <TableRow>
      <TableCell>
        <Box display="flex">
          <IconButton aria-label="edit" onClick={handleSave} disabled={isInvalid}>
            <CheckIcon className={clsx({ [classes.green]: !isInvalid })} />
          </IconButton>
          <IconButton aria-label="cancel" onClick={() => setEditId(null)}>
            <CloseIcon className={classes.red} />
          </IconButton>
        </Box>
      </TableCell>
      <TableCell>
        <Select value={isActive} onChange={e => setIsActive(e.target.value)}>
          <MenuItem value="Active">Active</MenuItem>
          <MenuItem value="Not active">Not active</MenuItem>
        </Select>
      </TableCell>
      <TableCell>
        <AuthorSelect
          hideInputLabel
          value={authorSlug}
          handleChange={handleAuthorSlugChange}
          authors={authors}
        />
      </TableCell>
      <TableCell>
        <Box display="flex" flexDirection="column">
          <ProductSelect
            hideInputLabel
            value={product}
            handleChange={handleProductChange}
            products={products}
          />
          <div className={classes.divider} />
          {product !== 'select' && (
            <PurchaseOptionSelect
              hideInputLabel
              value={purchaseOption}
              handleChange={handlePurchaseOptionChange}
              purchaseOptions={purchaseOptions}
            />
          )}
        </Box>
      </TableCell>
      <TableCell>
        <Select value={commissionType} onChange={e => setCommissionType(e.target.value)}>
          <MenuItem value="fixed">Fixed amount</MenuItem>
          <MenuItem value="percentage">Percentage</MenuItem>
        </Select>
      </TableCell>
      <TableCell align="right">
        <TextField
          inputProps={{
            min: 0,
            step: 0.5,
          }}
          type="number"
          value={amount || 0}
          onChange={e => {
            setAmount(isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value))
          }}
        />
      </TableCell>
    </TableRow>
  )
}

EditCommissionRow.propTypes = {
  authors: PropTypes.arrayOf(PropTypes.object).isRequired,
  commission: PropTypes.object.isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  setCommissionPercentages: PropTypes.func.isRequired,
  year: PropTypes.string.isRequired,
  setEditId: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired, // from notistack
}

export default withSnackbar(EditCommissionRow)
