import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

import MyAppBar from './MyAppBar'
import Sidebar from './Sidebar'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: {
    ...theme.mixins.toolbar,
    top: 4,
    position: 'relative',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    top: 4,
  },
  container: {
    top: 4,
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}))

const Layout = ({ children, title }) => {
  const classes = useStyles()

  const [isSidebarOpen, setSidebarOpen] = React.useState(false)
  const handleDrawerOpen = () => {
    setSidebarOpen(true)
  }
  const handleDrawerClose = () => {
    setSidebarOpen(false)
  }

  return (
    <div className={classes.root}>
      <MyAppBar handleDrawerOpen={handleDrawerOpen} isSidebarOpen={isSidebarOpen} title={title} />
      <Sidebar handleDrawerClose={handleDrawerClose} isOpen={isSidebarOpen} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {children}
        </Container>
      </main>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.any,
}

Layout.defaultProps = {
  title: null,
}

export default Layout
