import React from 'react'
import PropTypes from 'prop-types'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'

const ProductSelect = ({ hideInputLabel, value, products, handleChange }) => {
  const productOptions = products
    .filter(p => p.data.product && p.data.product.data)
    .map(p => ({
      title: p.data.product.data.title[0].text,
    }))
  const sortedProductOptions = uniqBy(
    sortBy(productOptions, p => p.title.trim().toLowerCase()),
    'title'
  )

  return (
    <>
      {!hideInputLabel && <InputLabel htmlFor="product-simple">Product</InputLabel>}
      <Select
        value={value}
        onChange={handleChange}
        inputProps={{
          name: 'product',
          id: 'product-simple',
        }}
      >
        <MenuItem value="select">Select a product</MenuItem>
        {sortedProductOptions.map(p => (
          <MenuItem key={p.title} value={p.title}>
            {p.title}
          </MenuItem>
        ))}
      </Select>
    </>
  )
}

ProductSelect.propTypes = {
  handleChange: PropTypes.func.isRequired,
  hideInputLabel: PropTypes.bool,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.string.isRequired,
}
ProductSelect.defaultProps = {
  hideInputLabel: false,
}

export default ProductSelect
