import React from 'react'
import Typography from '@material-ui/core/Typography'

import Layout from '../../Layout'

const AffiliatesReport = () => {
  return (
    <Layout title="Affiliates Report">
      <Typography variant="h5" component="h2" gutterBottom>
        Affiliates Report.
      </Typography>
      <Typography>TODO: for now use https://www.idevaffiliate.com/32887/admin/</Typography>
    </Layout>
  )
}
export default AffiliatesReport
