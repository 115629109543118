import React from 'react'
import PropTypes from 'prop-types'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const Address = ({ address: a }) => (
  <>
    <Typography>
      {a.first_name} {a.last_name}
      {a.address1 && (
        <>
          <br />
          {a.address1}
        </>
      )}
      {a.address2 && (
        <>
          <br />
          {a.address2}
        </>
      )}
      <br />
      {a.city} {a.postal_code}
      {a.region && (
        <>
          <br />
          {a.region}
        </>
      )}
      {a.country && (
        <>
          <br />
          {a.country}
        </>
      )}
    </Typography>
  </>
)

Address.propTypes = {
  address: PropTypes.object.isRequired,
}

const CustomerDetail = ({ transaction: t }) => {
  // console.log(t)

  return (
    <>
      <Box display="flex" width="100%" justifyContent="space-between">
        <div>
          <Typography variant="h5" component="h2" gutterBottom>
            Billing Address
          </Typography>
          {t._embedded['fx:billing_addresses'].map((a, index) => (
            <Address key={index} address={a} />
          ))}
        </div>

        {t._embedded['fx:shipments'] && (
          <div>
            <Typography variant="h5" component="h2" gutterBottom>
              Shipping Address
            </Typography>
            {t._embedded['fx:shipments'].map((a, index) => (
              <Address key={index} address={a} />
            ))}
          </div>
        )}
      </Box>

      <List dense disablePadding>
        <ListItem disableGutters>
          <ListItemText primary="IP Address:" secondary={t.customer_ip} />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText primary="User Agent:" secondary={t.user_agent} />
        </ListItem>
        {t._embedded['fx:payments'] && (
          <>
            {t._embedded['fx:payments'].map((p, index) => (
              <ListItem key={index} disableGutters>
                <ListItemText primary="Payment:" secondary={p.processor_response} />
              </ListItem>
            ))}
          </>
        )}
        {t._embedded['fx:custom_fields'] && (
          <>
            {t._embedded['fx:custom_fields'].map((f, index) => (
              <ListItem key={index} disableGutters>
                <ListItemText primary={`${f.name}:`} secondary={f.value} />
              </ListItem>
            ))}
          </>
        )}
      </List>
    </>
  )
}

CustomerDetail.propTypes = {
  transaction: PropTypes.object.isRequired,
}

export default CustomerDetail
