import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import netlifyIdentity from 'netlify-identity-widget'

import Routes from './components/Routes'
import Login from './components/Login'
import { loginUser, logoutUser } from './lib/identity'

import UserContext from './UserContext'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}))

export default function ButtonAppBar() {
  const classes = useStyles()
  const [user, setUser] = useState(null)

  useEffect(() => {
    const user = netlifyIdentity.currentUser()
    if (user) {
      setUser(user)
    }

    netlifyIdentity.on('login', user => {
      setUser(user)
      loginUser()
    })

    netlifyIdentity.on('logout', () => {
      setUser(null)
      logoutUser()
    })
  }, [])

  return (
    <UserContext.Provider value={user}>
      <div className={classes.root}>{netlifyIdentity.currentUser() ? <Routes /> : <Login />}</div>
    </UserContext.Provider>
  )
}
