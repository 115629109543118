import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import clsx from 'clsx'

import UserContext from '../../UserContext'
import UserMenu from './UserMenu'

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    borderTop: `4px solid ${theme.palette.secondary.main}`,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  flexGrow: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}))

const MyAppBar = ({ handleDrawerOpen, isSidebarOpen, title }) => {
  const classes = useStyles()

  return (
    <UserContext.Consumer>
      {user => {
        return (
          <AppBar
            position="absolute"
            className={clsx(classes.appBar, isSidebarOpen && classes.appBarShift)}
          >
            <Toolbar className={classes.toolbar}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={clsx(classes.menuButton, isSidebarOpen && classes.menuButtonHidden)}
              >
                <MenuIcon />
              </IconButton>
              {title && (
                <Typography component="h1" variant="h6" color="inherit" noWrap>
                  {title}
                </Typography>
              )}
              <div className={classes.flexGrow} />
              <UserMenu full_name={user.user_metadata.full_name} />
            </Toolbar>
          </AppBar>
        )
      }}
    </UserContext.Consumer>
  )
}

MyAppBar.propTypes = {
  handleDrawerOpen: PropTypes.func.isRequired,
  isSidebarOpen: PropTypes.bool.isRequired,
  title: PropTypes.any,
}
MyAppBar.defaultProps = {
  title: null,
}

export default MyAppBar
