// TODO: ref https://react-pdf.org/repl
import React from 'react'
import PropTypes from 'prop-types'
import { Document, Page, View, Text, StyleSheet } from '@react-pdf/renderer'
import formatDate from 'date-fns/format'

import globalStyles from '../../../PDFs/styles'
import PDFHeader from '../../../PDFs/PDFHeader'
import PDFFooter from '../../../PDFs/PDFFooter'
import { GBP } from '../../../../../lib/helpers'
import Table from './Table'

const styles = StyleSheet.create({
  container: {
    borderTopColor: '#eee',
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    paddingTop: 10,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 4,
    width: '100%',
  },
  small: {
    fontSize: 14,
  },
})

const getMonth = m => {
  let x = (parseInt(m) + 1).toString()
  if (x.length === 1) x = `0${x}`
  return formatDate(new Date(`2020-${x}-01`), 'MMMM')
}

const PDFReport = ({ author, reportData, year, month }) => {
  // console.log(reportData, month)
  let title
  let subtitle
  if (month === 'all') {
    title = `${author} Ginger GM Commissions Report ${year}`
    subtitle = `gingergm.com Commissions ${year}`
  } else {
    title = `${author} Ginger GM Commissions Report ${month} ${year}`
    subtitle = `gingergm.com Commissions ${getMonth(month)} ${year}`
  }

  return (
    <Document title={title} author="Ginger GM">
      <Page style={globalStyles.body} size="A4">
        <PDFHeader title={`${author}`} subtitle={subtitle} />
        <View style={styles.container}>
          <View style={styles.row}>
            <Text>Total ({reportData.reduce((sum, p) => sum + p.count, 0)} sales):</Text>
            <Text>{GBP(reportData.reduce((sum, p) => sum + p.total, 0))}</Text>
          </View>
          <Table reportData={reportData} />
        </View>
        <View
          style={{
            borderTopColor: '#eee',
            borderTopStyle: 'solid',
            borderTopWidth: 1,
            marginVertical: 10,
            paddingVertical: 10,
            fontSize: 12,
          }}
          wrap={false}
        >
          <Text>Notes:</Text>
          <Text style={{ marginTop: 10, paddingLeft: 10 }}>
            - Where the average commission per sale is below a fixed commission amount, this is due
            to products being sold at a discounted price.
          </Text>
          <Text style={{ marginTop: 10, paddingLeft: 10 }}>
            - See the attached CSV file for individual sale and discount details.
          </Text>
        </View>
        <PDFFooter />
      </Page>
    </Document>
  )
}

PDFReport.propTypes = {
  author: PropTypes.string.isRequired,
  reportData: PropTypes.arrayOf(PropTypes.object).isRequired,
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
}

export default PDFReport
