import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { KeyboardDatePicker } from '@material-ui/pickers'
import isValid from 'date-fns/isValid'
import isPast from 'date-fns/isPast'
import isFuture from 'date-fns/isFuture'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import { withSnackbar } from 'notistack'
import axios from 'axios'

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 700,
  },
  datePicker: {
    marginRight: theme.spacing(2),
    marginTop: 0,
  },
}))

const CouponDates = ({ couponId, start_date, end_date, enqueueSnackbar }) => {
  const classes = useStyles()
  const [startDateValue, setStartDateValue] = useState(start_date)
  const [endDateValue, setEndDateValue] = useState(end_date)
  const [isSaving, setSaving] = useState(false)
  const isFirstUpdate = useRef(true)

  // Is the coupon valid today?
  let isCouponValidToday = true
  if (startDateValue && !isPast(parseISO(startDateValue))) isCouponValidToday = false
  if (endDateValue && !isFuture(parseISO(endDateValue))) isCouponValidToday = false

  useEffect(() => {
    let didCancel = false

    const save = async () => {
      try {
        setSaving(true)

        const s = startDateValue ? format(parseISO(startDateValue), 'yyyy-MM-dd') : '0000-00-00'
        const e = endDateValue ? format(parseISO(endDateValue), 'yyyy-MM-dd') : '0000-00-00'

        await axios.get(
          `/makeFoxyRequest?url=https://api.foxycart.com/coupons/${couponId}&method=patch&start_date=${s}&end_date=${e}`
        )
        // console.log(response.data)
        enqueueSnackbar(`Dates updated.`, { variant: 'success' })
      } catch (e) {
        console.log(e)
        enqueueSnackbar(`Coupon dates update failed: ${e}.`, { variant: 'error' })
      } finally {
        if (didCancel) return
        setSaving(false)
      }
    }

    if (isFirstUpdate.current) {
      isFirstUpdate.current = false
    } else {
      save()
    }

    return () => {
      didCancel = true
    }
  }, [startDateValue, endDateValue, couponId, enqueueSnackbar])

  return (
    <Box mt={2}>
      <Typography>This coupon is{isCouponValidToday ? '' : ' not'} valid today.</Typography>
      <Box>
        <KeyboardDatePicker
          className={classes.datePicker}
          disabled={isSaving}
          disableToolbar
          variant="inline"
          format="yyyy-MM-dd"
          margin="normal"
          id="start-date"
          label="Start date"
          value={startDateValue}
          onChange={d => {
            if (!(isValid(d) || d === null)) return
            if (d === null) {
              setStartDateValue(null)
            } else {
              setStartDateValue(format(d, 'yyyy-MM-dd'))
            }
          }}
          KeyboardButtonProps={{
            'aria-label': 'change start date',
          }}
        />
        <KeyboardDatePicker
          className={classes.datePicker}
          disabled={isSaving}
          disableToolbar
          variant="inline"
          format="yyyy-MM-dd"
          margin="normal"
          id="end-date"
          label="End date"
          value={endDateValue}
          onChange={d => {
            if (!(isValid(d) || d === null)) return
            if (d === null) {
              setEndDateValue(null)
            } else {
              setEndDateValue(format(d, 'yyyy-MM-dd'))
            }
          }}
          KeyboardButtonProps={{
            'aria-label': 'change end date',
          }}
        />
      </Box>
      <Typography variant="caption">
        Leave dates blank for unrestricted. You can set both, or only one of the dates. Note: the
        update will fail if both dates are set and the start date is{' '}
        <span className={classes.bold}>after</span> the end date.
      </Typography>
    </Box>
  )
}

CouponDates.propTypes = {
  couponId: PropTypes.string.isRequired,
  start_date: PropTypes.string,
  end_date: PropTypes.string,
  enqueueSnackbar: PropTypes.func.isRequired, // from notistack
}
CouponDates.defaultProps = {
  start_date: null,
  end_date: null,
}

export default withSnackbar(CouponDates)
