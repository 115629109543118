import React from 'react'
import PropTypes from 'prop-types'
import { Text, View, StyleSheet } from '@react-pdf/renderer'

import { GBP } from '../../../../../lib/helpers'

const styles = StyleSheet.create({
  container: {
    borderTopColor: '#eee',
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    paddingTop: 10,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 4,
    width: '100%',
  },
})

const Totals = ({ commissionPercentage, totalSalesPrice, itemCount }) => {
  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <Text>Total sales ({itemCount}):</Text>
        <Text>{GBP(totalSalesPrice)}</Text>
      </View>
      <View style={styles.row}>
        <Text>Total payable to iChess @ {commissionPercentage}% commission:</Text>
        <Text>{GBP((totalSalesPrice * commissionPercentage) / 100)}</Text>
      </View>
    </View>
  )
}

Totals.propTypes = {
  commissionPercentage: PropTypes.number.isRequired,
  itemCount: PropTypes.number.isRequired,
  totalSalesPrice: PropTypes.number.isRequired,
}

export default Totals
