import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'

const AlertDialog = ({
  dialogContent,
  dialogTitle,
  handleClose,
  handleOk,
  isOkButtonDisabled,
  isOpen,
  okButtonText,
}) => {
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        {dialogContent}
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleOk} color="primary" disabled={isOkButtonDisabled}>
            {okButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

AlertDialog.propTypes = {
  dialogContent: PropTypes.any.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isOkButtonDisabled: PropTypes.bool,
  okButtonText: PropTypes.string,
}
AlertDialog.defaultProps = {
  isOkButtonDisabled: false,
  okButtonText: 'OK',
}

export default AlertDialog
