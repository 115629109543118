import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import axios from 'axios'
import netlifyIdentity from 'netlify-identity-widget'
import CssBaseline from '@material-ui/core/CssBaseline'
import { SnackbarProvider } from 'notistack'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import App from './App'
import theme from './theme'
import * as serviceWorker from './serviceWorker'

const isDev = process.env.NODE_ENV === 'development'

axios.defaults.baseURL = isDev ? 'http://localhost:9000/' : '/.netlify/functions/'

netlifyIdentity.init()

ReactDOM.render(
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <SnackbarProvider maxSnack={3}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </SnackbarProvider>
  </MuiPickersUtilsProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
