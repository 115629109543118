import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Link from '@material-ui/core/Link'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import axios from 'axios'

import EditableText from '../../../EditableText'
import DeleteCouponCode from './DeleteCouponCode'
import TransactionsDialog from './TransactionsDialog'

const useStyles = makeStyles(theme => ({
  cell: {
    border: 0,
  },
}))

const CodeRow = ({ couponCode, deleteCouponCode }) => {
  const classes = useStyles()
  const [isTransactionsDialogOpen, setTransactionsDialogOpen] = useState(false)

  return (
    <>
      <TableRow>
        <TableCell className={classes.cell}>
          <EditableText
            initialValue={couponCode.code}
            onSave={async value => {
              await axios.get(
                `/makeFoxyRequest?url=${
                  couponCode._links.self.href
                }&method=patch&code=${encodeURIComponent(value)}`
              )
            }}
            snackbarMessages={{
              success: 'Coupon code updated.',
              error: e => `Coupon code update failed: ${e}.`,
            }}
          />
        </TableCell>
        <TableCell align="right" className={classes.cell}>
          {couponCode.number_of_uses_to_date > 0 ? (
            <Link
              component="button"
              variant="body2"
              onClick={() => setTransactionsDialogOpen(true)}
            >
              {couponCode.number_of_uses_to_date}
            </Link>
          ) : (
            <span>None</span>
          )}
        </TableCell>
        <TableCell align="right" className={classes.cell}>
          <DeleteCouponCode coupon={couponCode} deleteCouponCode={deleteCouponCode} />
        </TableCell>
      </TableRow>
      <TransactionsDialog
        couponCode={couponCode}
        isOpen={isTransactionsDialogOpen}
        handleClose={() => setTransactionsDialogOpen(false)}
      />
    </>
  )
}

CodeRow.propTypes = {
  couponCode: PropTypes.object.isRequired,
  deleteCouponCode: PropTypes.func.isRequired,
}

export default CodeRow
