import { StyleSheet, Font } from '@react-pdf/renderer'

// https://gist.github.com/sadikay/d5457c52e7fb2347077f5b0fe5ba9300 for url
// Note: make sure to use https
Font.register({
  family: 'Roboto',
  src: 'https://fonts.gstatic.com/s/roboto/v16/zN7GBFwfMP4uA6AR0HCoLQ.ttf',
})

export default StyleSheet.create({
  body: {
    paddingTop: 20,
    paddingBottom: 50,
    paddingHorizontal: 35,
  },
  text: {
    fontSize: 16,
    lineHeight: 1.4,
    fontFamily: 'Roboto',
    fontWeight: 400,
  },
})
