import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Box from '@material-ui/core/Box'
import axios from 'axios'
import { withSnackbar } from 'notistack'

import { parseError } from '../../lib/helpers'
import CouponRow from './CouponRow'
import Layout from '../Layout'
import Pagination from '../Pagination'
import RTFM from './RTFM'
import NewCoupon from './NewCoupon'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}))

const TableHeader = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell style={{ width: 120 }}>Valid today?</TableCell>
        <TableCell>Coupon</TableCell>
        <TableCell align="right">Valid for products</TableCell>
        <TableCell align="right">Valid for categories</TableCell>
        <TableCell align="right">Discount(s)</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  )
}

const Coupons = ({ enqueueSnackbar }) => {
  const classes = useStyles()
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [coupons, setCoupons] = useState([])
  const [totalPages, setTotalPages] = useState(1)

  useEffect(() => {
    if (isLoaded) return
    let didCancel = false
    setIsLoaded(false)
    setError(false)

    const fetchCoupons = async () => {
      try {
        const response = await axios.get(
          `/makeFoxyRequest?url=https://api.foxycart.com/stores/22071/coupons?limit=${perPage}&offset=${(page -
            1) *
            perPage}&order=date_modified desc&zoom=coupon_item_categories,coupon_codes&name=GGM::*`
        )
        // console.log(response.data)
        const data = response.data['_embedded']['fx:coupons'].map(c => {
          return {
            ...c,
            id: c._links.self.href.replace('https://api.foxycart.com/coupons/', ''),
          }
        })
        if (didCancel) return
        // console.log(data)
        setCoupons(data)
        setTotalPages(Math.ceil(parseInt(response.data.total_items, 10) / perPage))
      } catch (e) {
        if (didCancel) return
        setError(parseError(e))
      } finally {
        if (!didCancel) {
          setIsLoaded(true)
        }
      }
    }

    fetchCoupons()

    return () => {
      didCancel = true
    }
  }, [perPage, page, isLoaded])

  const deleteCoupon = async coupon => {
    try {
      const response = await axios.get(
        `/makeFoxyRequest?url=${coupon._links.self.href}&method=delete`
      )
      enqueueSnackbar(response.data.message, { variant: 'success' })
      setIsLoaded(false)
    } catch (e) {
      enqueueSnackbar(parseError(e), { variant: 'error' })
    }
  }

  return (
    <Layout title="Coupons">
      <RTFM />
      <Box mb={2}>
        <NewCoupon reloadCoupons={() => setIsLoaded(false)} />
      </Box>
      <Paper className={classes.paper}>
        {error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <>
            {isLoaded ? (
              <>
                {coupons.length > 0 ? (
                  <>
                    <Pagination
                      isDisabled={!isLoaded}
                      page={page}
                      perPage={perPage}
                      perPageMenuItems={[5, 10, 25]}
                      setPerPage={x => {
                        setPerPage(x)
                        setIsLoaded(false)
                      }}
                      setPage={x => {
                        setPage(x)
                        setIsLoaded(false)
                      }}
                      totalPages={totalPages}
                    />
                    <Table>
                      <TableHeader />
                      <TableBody>
                        {coupons.map(c => (
                          <CouponRow key={c.name} coupon={c} deleteCoupon={() => deleteCoupon(c)} />
                        ))}
                      </TableBody>
                    </Table>
                  </>
                ) : (
                  <Typography>No coupons found.</Typography>
                )}
              </>
            ) : (
              <CircularProgress variant="indeterminate" />
            )}
          </>
        )}
      </Paper>
    </Layout>
  )
}

Coupons.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired, // from notistack
}

export default withSnackbar(Coupons)
