import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Paper, Table, TableHead, TableBody, TableCell, TableRow, Typography, CircularProgress } from '@material-ui/core'
import MUILink from '@material-ui/core/Link'
import axios from 'axios'
import sortBy from 'lodash/sortBy'
import { KeyboardDatePicker } from '@material-ui/pickers'
import format from 'date-fns/format'
import { CSVLink } from 'react-csv'
import isValid from 'date-fns/isValid'

import Layout from '../../Layout'
import { parseError , GBP } from '../../../lib/helpers'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  datePicker: {
    marginRight: theme.spacing(2),
  },
}))

const BestSellersReport = () => {
  const classes = useStyles()
  const [error, setError] = useState(false)
  const [isLoaded, setIsLoaded] = useState(true)
  const [reportData, setReportData] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  useEffect(() => {
    if (!startDate || !endDate) return

    let didCancel = false

    const fetchReport = async () => {
      setError(false)
      setIsLoaded(false)
      setReportData([])
      try {
        const response = await axios.get(
          `/fetchBestSellers?startDate=${startDate}&endDate=${endDate}`
        )
        // console.log(response.data)

        if (didCancel) return
        setReportData(sortBy(response.data, 'count').reverse())
      } catch (e) {
        if (didCancel) return
        setError(parseError(e))
      } finally {
        if (!didCancel) {
          setIsLoaded(true)
        }
      }
    }

    fetchReport()

    return () => {
      didCancel = true
    }
  }, [startDate, endDate])

  return (
    <Layout title="Best Sellers Report">
      <Paper className={classes.paper}>
        <Box display="flex" justifyContent="center">
          <KeyboardDatePicker
            className={classes.datePicker}
            disabled={!isLoaded}
            disableToolbar
            variant="inline"
            format="yyyy-MM-dd"
            margin="normal"
            id="start-date"
            label="Start date"
            value={startDate}
            onChange={d => {
              if (!isValid(d)) return
              setStartDate(format(d, 'yyyy-MM-dd'))
            }}
            KeyboardButtonProps={{
              'aria-label': 'change start date',
            }}
          />
          <KeyboardDatePicker
            className={classes.datePicker}
            disabled={!isLoaded}
            disableToolbar
            variant="inline"
            format="yyyy-MM-dd"
            margin="normal"
            id="end-date"
            label="End date"
            value={endDate}
            onChange={d => {
              if (!isValid(d)) return
              setEndDate(format(d, 'yyyy-MM-dd'))
            }}
            KeyboardButtonProps={{
              'aria-label': 'change end date',
            }}
          />
        </Box>
        {!isLoaded && <CircularProgress />}
        {error && <Typography color="error">{error}</Typography>}
        {reportData.length > 0 && (
          <>
            <Box my={2} display="flex" justifyContent="center" width="100%">
              <MUILink
                component={CSVLink}
                data={reportData}
                filename={`ggm-best-sellers.csv`}
                className={classes.actionLink}
                variant="body1"
              >
                Download CSV file
              </MUILink>
            </Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product code</TableCell>
                  <TableCell>Count</TableCell>
                  <TableCell>GBP (Gross)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reportData.map(row => {
                  return (
                    <TableRow key={row.code}>
                      <TableCell>{row.code}</TableCell>
                      <TableCell>{row.count}</TableCell>
                      <TableCell>{GBP(row.amountGross)}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </>
        )}
      </Paper>
    </Layout>
  )
}
export default BestSellersReport
