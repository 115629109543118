import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import axios from 'axios'
import format from 'date-fns/format'

import ExternalLink from '../../ExternalLink'
import { parseError, GBP } from '../../../lib/helpers'

const useStyles = makeStyles(theme => ({
  flexGrow: {
    flexGrow: 1,
  },
  table: {
    margin: theme.spacing(2, 0),
  },
}))

const Refunds = ({ transactionId, gatewayTypes }) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)
  const [refunds, setRefunds] = useState([])

  useEffect(() => {
    let didCancel = false
    setIsLoading(true)
    setError(false)

    const fetchRefunds = async () => {
      try {
        const response = await axios.get(`/fetchRefunds?transactionId=${transactionId}`)
        // console.log(response.data)
        if (didCancel) return
        setRefunds(response.data)
      } catch (e) {
        if (didCancel) return
        setError(parseError(e))
      } finally {
        if (!didCancel) {
          setIsLoading(false)
        }
      }
    }

    fetchRefunds()
  }, [transactionId])

  const isStripe = gatewayTypes.some(g => g.toLowerCase().indexOf('stripe') > -1)
  const isPaypal = gatewayTypes.some(g => g.toLowerCase().indexOf('paypal') > -1)

  return (
    <>
      <Typography variant="h5" component="h2" gutterBottom>
        Refunds
      </Typography>
      {isStripe && (
        <Typography variant="body2" gutterBottom>
          <ExternalLink href="https://stripe.com/gb" linkText="Refund Stripe orders here" />
        </Typography>
      )}
      {isPaypal && (
        <Typography variant="body2" gutterBottom>
          <ExternalLink
            href="https://www.paypal.com/uk/home"
            linkText="Refund PayPal orders here"
          />
        </Typography>
      )}
      {isLoading && <CircularProgress />}
      {error && <Typography color="error">{error}</Typography>}
      {!isLoading && isStripe && !error && refunds.length > 0 && (
        <Table size="small" className={classes.table}>
          <TableBody>
            {refunds.map(r => (
              <TableRow key={r.stripe_refund_id}>
                <TableCell>
                  {format(r.created * 1000, 'yyyy-MM-dd')}
                  <br />
                  <ExternalLink href={r.stripe_receipt_url} linkText="receipt" />
                </TableCell>
                <TableCell>
                  {GBP(r.amount / 100)}
                  <br />
                  {r.reason}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {!isLoading && isStripe && !error && refunds.length === 0 && (
        <Typography variant="body2">No Stripe refunds found.</Typography>
      )}
      <div className={classes.flexGrow} />
      <Typography variant="caption">
        Only Stripe refunds are shown here. Paypal refunds are not currently shown (we need a Paypal
        Business Account to use the API).
      </Typography>
    </>
  )
}

Refunds.propTypes = {
  gatewayTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  transactionId: PropTypes.number.isRequired,
}

export default Refunds
