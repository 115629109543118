import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import Layout from '../Layout'
import SalesWidget from './SalesWidget'
import BestSellersWidget from './BestSellersWidget'
import TransactionsWidget from './TransactionsWidget'
import AffiliatesWidget from './AffiliatesWidget'
import ReviewsWidget from './ReviewsWidget'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 380,
  },
}))

export default function Dashboard() {
  const classes = useStyles()
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)

  return (
    <Layout title="Dashboard">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper className={fixedHeightPaper}>
            <SalesWidget />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className={fixedHeightPaper}>
            <BestSellersWidget />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <TransactionsWidget />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className={fixedHeightPaper}>
            <AffiliatesWidget />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className={fixedHeightPaper}>
            <ReviewsWidget />
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  )
}
