import React from 'react'
import PropTypes from 'prop-types'
import { Text, View, StyleSheet } from '@react-pdf/renderer'

import { GBP } from '../../../../../lib/helpers'

const styles = StyleSheet.create({
  container: {
    borderTopColor: '#eee',
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    fontSize: 14,
    lineHeight: 1.33,
    marginTop: 10,
    paddingBottom: 10,
    paddingTop: 10,
  },
  smallText: {
    fontSize: 10,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 4,
    width: '100%',
  },
  column1: {
    paddingVertical: 4,
    flex: 1,
  },
  column2: {
    paddingVertical: 4,
    textAlign: 'right',
    width: 150,
  },
  column3: {
    paddingVertical: 4,
    textAlign: 'right',
    width: 80,
  },
  headerRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 4,
    width: '100%',
    opacity: 0.7,
    fontSize: 12,
  },
})

const Table = ({ reportData }) => {
  return (
    <View style={styles.container}>
      <View style={styles.headerRow}>
        <View style={styles.column1}>
          <Text>Product (sales)</Text>
          <Text style={{ fontSize: 10 }}>Selection</Text>
        </View>
        <View style={styles.column2}>
          <Text>Commission</Text>
          <Text style={{ fontSize: 10 }}>Average per sale</Text>
        </View>
        <View style={styles.column3}>
          <View>
            <Text>Total</Text>
          </View>
        </View>
      </View>
      {reportData.map((p, index) => {
        return (
          <View key={index} style={styles.row}>
            <View style={styles.column1}>
              <Text>
                {p.name} ({p.count})
              </Text>
              <Text style={{ fontSize: 12 }}>{p.selection}</Text>
            </View>
            <View style={styles.column2}>
              <Text>{p.commissionDetails}</Text>
              <Text style={{ fontSize: 12 }}>Average per sale {GBP(p.total / p.count)}</Text>
            </View>
            <View style={styles.column3}>
              <View>
                <Text>{GBP(p.total)}</Text>
              </View>
            </View>
          </View>
        )
      })}
    </View>
  )
}

Table.propTypes = {
  reportData: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Table
