import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

import CouponName from './CouponName'

const NewCouponDialog = ({ isOpen, handleClose, name, setName, handleCreateCoupon }) => {
  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth>
      <DialogTitle>New coupon{name.trim().length > 0 && `: ${name}`}</DialogTitle>
      <DialogContent>
        <CouponName name={name} setName={setName} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={() => handleCreateCoupon()}
          color="primary"
          disabled={name.trim() === '' || name.trim().length > 50}
        >
          Create coupon
        </Button>
      </DialogActions>
    </Dialog>
  )
}

NewCouponDialog.propTypes = {
  handleCreateCoupon: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  setName: PropTypes.func.isRequired,
}

export default NewCouponDialog
