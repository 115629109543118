import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import axios from 'axios'
import { withSnackbar } from 'notistack'

import { parseError } from '../../../../../lib/helpers'

const useStyles = makeStyles(theme => ({
  italic: {
    fontStyle: 'italic',
  },
}))

const DeleteButton = ({
  commission: c,
  enqueueSnackbar,
  setCommissionPercentages,
  year,
  isDisabled,
}) => {
  // console.log(c)
  const classes = useStyles()
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false)

  const handleConfirmDialogOpen = () => setConfirmDialogOpen(true)
  const handleConfirmDialogClose = () => setConfirmDialogOpen(false)

  const handleDeleteCommission = async () => {
    try {
      const response = await axios.get(`/commissionsReportSettings?action=delete&id=${c._id}`)
      if (response.data.deletedCount === 0) {
        enqueueSnackbar(`Unable to delete commission ${c._id}.`)
      } else {
        const response = await axios.get(`/commissionsReportSettings?year=${year}`)
        setCommissionPercentages(response.data)
        enqueueSnackbar('Commission deleted.', { variant: 'success' })
      }
    } catch (e) {
      enqueueSnackbar(parseError(e), { variant: 'error' })
    } finally {
      //handleConfirmDialogClose()
    }
  }

  return (
    <>
      <IconButton aria-label="delete" onClick={handleConfirmDialogOpen} disabled={isDisabled}>
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={isConfirmDialogOpen && !isDisabled}
        onClose={handleConfirmDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete commission</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the commission{' '}
            <span className={classes.italic}>
              {year} {c.authorSlug} {c.purchaseOption}
            </span>
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose}>Cancel</Button>
          <Button onClick={handleDeleteCommission} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

DeleteButton.propTypes = {
  commission: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  setCommissionPercentages: PropTypes.func.isRequired,
  year: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
}

export default withSnackbar(DeleteButton)
