import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Box from '@material-ui/core/Box'

import ProductSelect from '../../../ProductSelect'
import PurchaseOptionSelect from '../../../PurchaseOptionSelect'
import { GBP } from '../../../../lib/helpers'

const AddProductDialog = ({ isOpen, handleClose, handleAdd, products }) => {
  // console.log(products)
  const [product, setProduct] = useState('select')
  const [purchaseOption, setPurchaseOption] = useState('select')

  const allPurchaseOptions = products.slice()
  const purchaseOptions = []
  if (product !== 'select') {
    allPurchaseOptions.forEach(p => {
      if (!p.data.product || !p.data.product.data) return
      if (p.data.product.data.title[0].text === product) {
        // console.log(p)
        purchaseOptions.push({ value: p.uid, label: `${p.data.description} ${GBP(p.data.price)}` })
      }
    })
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth>
      <DialogTitle>Add a product</DialogTitle>
      <DialogContent>
        <ProductSelect
          products={products}
          value={product}
          handleChange={e => setProduct(e.target.value)}
          hideInputLabel
        />
        {product !== 'select' && (
          <Box mt={2}>
            <PurchaseOptionSelect
              value={purchaseOption}
              purchaseOptions={purchaseOptions}
              handleChange={e => setPurchaseOption(e.target.value)}
              hideInputLabel
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleAdd(purchaseOption)
            setProduct('select')
            setPurchaseOption('select')
          }}
          color="primary"
          disabled={purchaseOption === 'select'}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AddProductDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default AddProductDialog
