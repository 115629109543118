import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import sortBy from 'lodash/sortBy'

const AddCategoryDialog = ({ isOpen, handleClose, handleAdd, categories }) => {
  // console.log(categories)
  const [category, setCategory] = useState('select')

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth>
      <DialogTitle>Add a category</DialogTitle>
      <DialogContent>
        <Select
          value={category}
          onChange={e => setCategory(e.target.value)}
          inputProps={{
            name: 'category',
            id: 'category-simple',
          }}
        >
          <MenuItem value="select">Select a category</MenuItem>
          {sortBy(categories, c => c.name.toLowerCase()).map(c => (
            <MenuItem key={c.code} value={c}>
              {c.name}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleAdd(category)
            setCategory('select')
          }}
          color="primary"
          disabled={category === 'select'}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AddCategoryDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default AddCategoryDialog
