import React from 'react'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import Row from './Row'

const TransactionsTable = ({ transactions }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Transaction ID</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>Items</TableCell>
          <TableCell align="right">Order Total</TableCell>
          <TableCell>Customer</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {transactions.map(row => (
          <Row key={row.id} transaction={row} />
        ))}
      </TableBody>
    </Table>
  )
}

TransactionsTable.propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default TransactionsTable
