import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Box'
import MUILink from '@material-ui/core/Link'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import axios from 'axios'
import { withSnackbar } from 'notistack'

import RTFM from '../RTFM'
import Codes from './Codes'
import Details from './Details'
import Products from './Products'
import Layout from '../../Layout'

import { parseError } from '../../../lib/helpers'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  tabPanel: {
    padding: theme.spacing(2),
  },
  padding: {
    padding: theme.spacing(0, 2),
  },
}))

const CouponDetail = ({ match, enqueueSnackbar }) => {
  const classes = useStyles()
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState(false)
  const [coupon, setCoupon] = useState(null)
  const [activeTab, setActiveTab] = useState(0)

  useEffect(() => {
    let didCancel = false
    if (isLoaded) return

    setError(false)

    const fetchCoupon = async () => {
      try {
        const response = await axios.get(
          `/makeFoxyRequest?url=https://api.foxycart.com/coupons/${match.params.id}&zoom=coupon_item_categories,coupon_codes`
        )
        // console.log(response.data)
        if (didCancel) return
        setCoupon(response.data)
      } catch (e) {
        if (didCancel) return
        setError(parseError(e))
      } finally {
        if (!didCancel) {
          setIsLoaded(true)
        }
      }
    }

    fetchCoupon()

    return () => {
      didCancel = true
    }
  }, [match.params.id, isLoaded])

  const deleteCouponCode = async url => {
    try {
      const response = await axios.get(`/makeFoxyRequest?url=${url}&method=delete`)
      setIsLoaded(false)
      enqueueSnackbar(response.data.message, { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(parseError(e), { variant: 'error' })
    }
  }

  const addCouponCode = async code => {
    try {
      const response = await axios.get(
        `/makeFoxyRequest?url=${coupon._links['fx:coupon_codes'].href}&method=post&code=${code}`
      )
      setIsLoaded(false)
      // console.log(response)
      enqueueSnackbar(response.data.message, { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(parseError(e), { variant: 'error' })
    }
  }

  return (
    <Layout title={coupon ? `Coupon ${coupon.name}` : 'Coupon'}>
      <Container maxWidth="md">
        <RTFM />
        <MUILink component={Link} to="/coupons">
          <Box display="inline-flex" alignItems="center">
            <ChevronLeftIcon />
            Back to all coupons
          </Box>
        </MUILink>
        <Paper className={classes.paper}>
          <Tabs
            value={activeTab}
            onChange={(_, tab) => setActiveTab(tab)}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Coupon details" />
            <Tab label="Codes" />
            <Tab label="Products &amp; Categories" />
          </Tabs>

          {error && (
            <div role="tabpanel" className={classes.tabPanel}>
              <Typography color="error">{error}</Typography>
            </div>
          )}

          {!isLoaded && (
            <div role="tabpanel" className={classes.tabPanel}>
              <CircularProgress variant="indeterminate" />
            </div>
          )}

          {isLoaded && !error && (
            <>
              <div role="tabpanel" hidden={error || activeTab !== 0} className={classes.tabPanel}>
                <Details coupon={coupon} couponId={match.params.id} />
              </div>

              <div role="tabpanel" hidden={error || activeTab !== 1} className={classes.tabPanel}>
                <Codes
                  coupon={coupon}
                  deleteCouponCode={deleteCouponCode}
                  addCouponCode={addCouponCode}
                />
              </div>

              <div role="tabpanel" hidden={error || activeTab !== 2} className={classes.tabPanel}>
                <Products coupon={coupon} />
              </div>
            </>
          )}
        </Paper>
      </Container>
    </Layout>
  )
}

CouponDetail.propTypes = {
  match: PropTypes.object.isRequired, // from react-router
  enqueueSnackbar: PropTypes.func.isRequired,
}

export default withSnackbar(CouponDetail)
