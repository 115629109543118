import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Coupons from './Coupons'
import Dashboard from './Dashboard'
import Reports from './Reports'
import Transactions from './Transactions'
import TransactionDetail from './Transactions/TransactionDetail'
import CouponDetail from './Coupons/CouponDetail'
import Customers from './Customers'
import Videos from './Videos'
import Reviews from './Reviews'
import UserContext from '../UserContext'
import NoMatch from './NoMatch'
import IChessReport from './Reports/IChessReport'
import VATReport from './Reports/VATReport'
import CommissionsReport from './Reports/CommissionsReport'
import AffiliatesReport from './Reports/AffiliatesReport'
import Killerd4EBookReport from './Reports/Killerd4EBookReport'
import BestSellersReport from './Reports/BestSellersReport'
import ReceiptsReport from './Reports/ReceiptsReport'

const Routes = () => {
  return (
    <UserContext.Consumer>
      {user => {
        if (!user) return null // wait for login
        return (
          <Router>
            <Switch>
              <Route exact path="/" component={Dashboard} />
              <Route exact path="/coupons/" component={Coupons} />
              <Route exact path="/coupons/:id" component={CouponDetail} />
              <Route exact path="/reports/" component={Reports} />
              <Route exact path="/reports/ichess" component={IChessReport} />
              <Route exact path="/reports/vat" component={VATReport} />
              <Route exact path="/reports/commissions" component={CommissionsReport} />
              <Route exact path="/reports/killer-d4-ebook" component={Killerd4EBookReport} />
              <Route exact path="/reports/affiliates" component={AffiliatesReport} />
              <Route exact path="/reports/best-sellers" component={BestSellersReport} />
              <Route exact path="/reports/receipts" component={ReceiptsReport} />
              <Route exact path="/transactions/" component={Transactions} />
              <Route exact path="/transactions/:id" component={TransactionDetail} />
              <Route exact path="/customers/" component={Customers} />
              <Route exact path="/videos/" component={Videos} />
              <Route exact path="/reviews/" component={Reviews} />
              <Route exact component={NoMatch} />
            </Switch>
          </Router>
        )
      }}
    </UserContext.Consumer>
  )
}

export default Routes
