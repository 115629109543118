import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/InfoOutlined'

const useStyles = makeStyles(theme => ({
  infoButton: {
    color: 'inherit',
    display: 'inline-block',
    padding: theme.spacing(0.5),
    top: -2,
  },
  icon: {
    color: 'inherit',
  },
}))

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip)

const InfoButton = ({ tooltipContent, onOpen }) => {
  const classes = useStyles()

  return (
    <HtmlTooltip title={tooltipContent} onOpen={onOpen}>
      <IconButton
        aria-label="info"
        color="primary"
        className={classes.infoButton}
        fontSize="inherit"
      >
        <InfoIcon className={classes.icon} />
      </IconButton>
    </HtmlTooltip>
  )
}

InfoButton.propTypes = {
  onOpen: PropTypes.func,
  tooltipContent: PropTypes.any,
}
InfoButton.defaultProps = {
  onOpen: () => {},
  tooltipContent: null,
}

export default InfoButton
