import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import { withSnackbar } from 'notistack'
import axios from 'axios'

import { parseError } from '../../../lib/helpers'
import NewCouponDialog from './NewCouponDialog'

const useStyles = makeStyles(theme => ({
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
}))

const NewCoupon = ({ enqueueSnackbar, reloadCoupons }) => {
  const classes = useStyles()
  const [isDialogOpen, setDialogOpen] = useState(false)
  const [name, setName] = useState('')

  const handleClose = () => {
    setDialogOpen(false)
    setName('')
  }

  const handleCreateCoupon = async () => {
    handleClose()
    try {
      const response = await axios.get(
        `/makeFoxyRequest?url=https://api.foxycart.com/stores/22071/coupons&method=post&name=GGM::${name}&coupon_discount_type=quantity_percentage&coupon_discount_details=0-0`
      )
      enqueueSnackbar(response.data.message, { variant: 'success' })
      reloadCoupons()
    } catch (e) {
      enqueueSnackbar(`Coupon create failed: ${parseError(e)}`, { variant: 'error' })
    }
  }

  return (
    <>
      <Button variant="outlined" onClick={() => setDialogOpen(true)}>
        New coupon
        <AddIcon className={classes.rightIcon} />
      </Button>
      <NewCouponDialog
        isOpen={isDialogOpen}
        handleClose={handleClose}
        handleCreateCoupon={handleCreateCoupon}
        setName={setName}
        name={name}
      />
    </>
  )
}

NewCoupon.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired, // from notistack
  reloadCoupons: PropTypes.func.isRequired,
}

export default withSnackbar(NewCoupon)
