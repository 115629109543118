import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MenuItem from '@material-ui/core/MenuItem'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import axios from 'axios'
import { withSnackbar } from 'notistack'

import YearSelect from '../../YearSelect'
import { parseError } from '../../../../lib/helpers'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
}))

const CopyButton = ({ className, year, enqueueSnackbar, setCommissionPercentages }) => {
  const classes = useStyles()
  const [isCopyDialogOpen, setCopyDialogOpen] = useState(false)
  const [isLoaded, setLoaded] = useState(true)
  const [fromYear, setFromYear] = useState('select')

  const handleCopyDialogOpen = () => setCopyDialogOpen(true)
  const handleCopyDialogClose = () => {
    setFromYear('select')
    setCopyDialogOpen(false)
  }
  const handleFromYearChange = e => setFromYear(e.target.value)

  const handleCopyCommission = async () => {
    // console.log(`copy to ${year} from ${fromYear}`)
    try {
      setLoaded(false)
      const addResponse = await axios.get(
        `/commissionsReportSettings?action=copy&toYear=${year}&fromYear=${fromYear}`
      )
      // console.log(addResponse.data)
      const response = await axios.get(`/commissionsReportSettings?year=${year}`)
      setCommissionPercentages(response.data)
      enqueueSnackbar(`${addResponse.data.count} commissions copied from ${fromYear}.`, {
        variant: 'success',
      })
    } catch (e) {
      enqueueSnackbar(parseError(e), { variant: 'error' })
    } finally {
      handleCopyDialogClose()
      setFromYear('select')
      setLoaded(true)
    }
  }

  return (
    <>
      <Button className={className} variant="outlined" onClick={handleCopyDialogOpen}>
        Copy commissions
        <FileCopyIcon className={classes.rightIcon} />
      </Button>
      <Dialog
        fullWidth
        open={isCopyDialogOpen}
        onClose={handleCopyDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Copy commissions to {year}</DialogTitle>
        <DialogContent>
          <YearSelect
            value={fromYear}
            handleChange={handleFromYearChange}
            inputLabelText="Year to copy from"
            excludeYears={[year.toString()]}
            SelectMenuItem={<MenuItem value="select">Select a year</MenuItem>}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCopyDialogClose} disabled={!isLoaded}>
            Cancel
          </Button>
          <Button
            onClick={handleCopyCommission}
            color="primary"
            disabled={!isLoaded || fromYear === 'select'}
          >
            Copy commissions
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

CopyButton.propTypes = {
  className: PropTypes.string,
  year: PropTypes.string.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired, // from notistack
  setCommissionPercentages: PropTypes.func.isRequired,
}
CopyButton.defaultProps = {
  className: null,
}

export default withSnackbar(CopyButton)
