import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'

const Pagination = ({
  isDisabled,
  page,
  perPage,
  perPageMenuItems,
  totalPages,
  setPage,
  setPerPage,
}) => {
  return (
    <Box alignItems="center" display="flex" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        <IconButton
          aria-label="first page"
          disabled={isDisabled || page === 1}
          onClick={() => setPage(1)}
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton
          aria-label="previous page"
          disabled={isDisabled || page === 1}
          onClick={() => setPage(page - 1)}
        >
          <ChevronLeftIcon />
        </IconButton>
        <Typography>
          Page {page} of {totalPages}
        </Typography>
        <IconButton
          aria-label="next page"
          disabled={isDisabled || page === totalPages}
          onClick={() => setPage(page + 1)}
        >
          <ChevronRightIcon />
        </IconButton>
        <IconButton
          aria-label="last page"
          disabled={isDisabled || page === totalPages}
          onClick={() => setPage(totalPages)}
        >
          <LastPageIcon />
        </IconButton>
      </Box>
      <Box display="flex" alignItems="center">
        <InputLabel shrink htmlFor="per-page-label-placeholder">
          Per page
        </InputLabel>
        <Select
          disabled={isDisabled}
          inputProps={{
            name: 'per-page',
            id: 'per-page-label-placeholder',
          }}
          onChange={e => {
            setPerPage(parseInt(e.target.value, 10))
            setPage(1)
          }}
          value={perPage.toString()}
        >
          {perPageMenuItems.map(i => (
            <MenuItem key={i} value={i.toString()}>
              {i}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  )
}

Pagination.propTypes = {
  isDisabled: PropTypes.bool,
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  perPageMenuItems: PropTypes.arrayOf(PropTypes.number),
  totalPages: PropTypes.number.isRequired,
  setPerPage: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
}
Pagination.defaultProps = {
  isDisabled: false,
  perPageMenuItems: [5, 10, 25, 50, 100, 300],
}

export default Pagination
