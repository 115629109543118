import React from 'react'
import PropTypes from 'prop-types'
import { Text, Image, StyleSheet, View } from '@react-pdf/renderer'

import logo from '../../../ggm-logo@2x.png'

const styles = StyleSheet.create({
  view: {
    alignItems: 'center',
    marginBottom: 10,
  },
  image: {
    height: 140,
    width: 270,
  },
  title: {
    fontSize: 26,
    fontFamily: 'Roboto',
    fontWeight: 400,
  },
  subtitle: {
    fontSize: 18,
    fontFamily: 'Roboto',
    fontWeight: 400,
    marginVertical: 4,
    opacity: 0.66,
  },
  subtitle2: {
    fontSize: 16,
    fontFamily: 'Roboto',
    fontWeight: 400,
    marginVertical: 4,
    opacity: 0.66,
  },
})

const PDFHeader = ({ title, subtitle, subtitle2 }) => (
  <View style={styles.view}>
    <Image style={styles.image} src={logo} />
    <Text style={styles.title}>{title}</Text>
    {subtitle && <Text style={styles.subtitle}>{subtitle}</Text>}
    {subtitle2 && <Text style={styles.subtitle2}>{subtitle2}</Text>}
  </View>
)

PDFHeader.propTypes = {
  subtitle: PropTypes.string,
  subtitle2: PropTypes.string,
  title: PropTypes.string.isRequired,
}
PDFHeader.defaultProps = {
  subtitle: null,
  subtitle2: null,
}

export default PDFHeader
