import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import MUILink from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Badge from '@material-ui/core/Badge'
import { Link } from 'react-router-dom'

import { parseError, GBP } from '../../lib/helpers'
import Title from '../Title'
import InfoButton from '../InfoButton'
import ExternalLink from '../ExternalLink'

const useStyles = makeStyles(theme => ({
  flexGrow: {
    flexGrow: 1,
  },
  table: {
    marginBottom: theme.spacing(2),
  },
  tabPanel: {
    padding: theme.spacing(2),
  },
  padding: {
    padding: theme.spacing(0, 2),
  },
}))

const CommissionsTable = ({ data }) => {
  const classes = useStyles()

  return (
    <Table size="small" className={classes.table}>
      <TableBody>
        {data.map(c => {
          return (
            <TableRow key={c.order_number}>
              <TableCell>{c.date}</TableCell>
              <TableCell>
                <MUILink component={Link} to={`/transactions/${c.order_number}`}>
                  {c.order_number}
                </MUILink>
              </TableCell>
              <TableCell>{c.username}</TableCell>
              <TableCell>{GBP(c.commission_amount)}</TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}
CommissionsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const AffiliatesWidget = () => {
  const classes = useStyles()
  const [isLoaded, setIsLoaded] = useState(true)
  const [error, setError] = useState(false)
  const [pending, setPending] = useState(null)
  const [approved, setApproved] = useState(null)
  const [activeTab, setActiveTab] = useState(0)

  useEffect(() => {
    let didCancel = false

    const fetchSalesData = async () => {
      setIsLoaded(false)
      setError(false)

      try {
        const promises = [
          // pending_approval
          // currently_approved
          // delayed
          // declined
          axios.get(
            `/makeAffiliatesRequest?url=/getCommissions.php?method=get&commission_type=pending_approval&iDisplayStart=0&iDisplayLength=5&iSortCol_0=5&sSortDir_0=desc&iSortingCols=1&bSortable_5=true`
          ),
          axios.get(
            `/makeAffiliatesRequest?url=/getCommissions.php?method=get&commission_type=currently_approved&iDisplayStart=0&iDisplayLength=5&iSortCol_0=5&sSortDir_0=desc&iSortingCols=1&bSortable_5=true`
          ),
        ]
        const [pending, approved] = await Promise.all(promises)
        // console.log(pending.data, approved.data)
        if (didCancel) return
        setPending(pending.data.data)
        setApproved(approved.data.data)
      } catch (e) {
        if (didCancel) return
        setError(parseError(e))
      } finally {
        if (!didCancel) {
          setIsLoaded(true)
        }
      }
    }

    fetchSalesData()

    return () => {
      didCancel = true
    }
  }, [])

  if (!isLoaded) return <CircularProgress variant="indeterminate" />

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
      <Title>
        Affiliate Commissions
        <InfoButton
          tooltipContent={
            <>
              <Typography gutterBottom>Where does this data come from?</Typography>
              <Typography variant="body2" gutterBottom>
                iDevAffiliate. The data should be up-to-date, but their API (and software) is
                generally crap, so don't be surprised if it isn't, or this widget randomly breaks.
              </Typography>
              <Typography gutterBottom>Why so few commissions?</Typography>
              <Typography variant="body2" gutterBottom>
                The API only shows unpaid commissions. Once a payment has been made to an affiliate,
                the associated commissions will be removed from the <em>approved</em> list.
              </Typography>
              <Typography gutterBottom>What are pending commissions?</Typography>
              <Typography variant="body2">
                All commissions need to be approved in the iDev admin.
              </Typography>
            </>
          }
        />
      </Title>
      {error && <Typography color="error">{error}</Typography>}
      {!error && isLoaded && (
        <>
          <Tabs
            value={activeTab}
            onChange={(_, tab) => setActiveTab(tab)}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab
              label={
                pending && pending.iTotalRecords > 0 ? (
                  <Badge
                    className={classes.padding}
                    color="secondary"
                    badgeContent={pending && pending.iTotalRecords}
                  >
                    Pending
                  </Badge>
                ) : (
                  'Pending'
                )
              }
            />
            <Tab
              label={
                approved && approved.iTotalRecords > 0 ? (
                  <Badge
                    className={classes.padding}
                    color="secondary"
                    badgeContent={approved && approved.iTotalRecords}
                  >
                    Approved
                  </Badge>
                ) : (
                  'Approved'
                )
              }
            />
          </Tabs>

          {pending && pending.aaData && (
            <div role="tabpanel" hidden={activeTab !== 0} className={classes.tabPanel}>
              {pending.aaData.length > 0 ? (
                <Typography gutterBottom>
                  Showing five most recent. Approve them in the{' '}
                  <ExternalLink
                    href="https://www.idevaffiliate.com/32887/admin/"
                    linkText="iDev admin"
                  />
                  .
                </Typography>
              ) : (
                <Typography gutterBottom>No pending commissions to show.</Typography>
              )}
              <CommissionsTable data={pending.aaData} />
            </div>
          )}

          {approved && approved.aaData && (
            <div role="tabpanel" hidden={activeTab !== 1} className={classes.tabPanel}>
              {approved.aaData.length > 0 ? (
                <Typography gutterBottom>Showing five most recent commissions.</Typography>
              ) : (
                <Typography gutterBottom>No approved commissions to show.</Typography>
              )}
              <CommissionsTable data={approved.aaData} />
            </div>
          )}
        </>
      )}

      <div className={classes.flexGrow} />

      <div className={classes.seeMore}>
        <MUILink component={Link} to="/reports">
          More affiliate reports
        </MUILink>
      </div>
    </Box>
  )
}

export default AffiliatesWidget
