import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
}))

const SidebarItem = ({ text, icon, href, isOpen }) => {
  const classes = useStyles()

  const inner = (
    <Link to={href} className={classes.link}>
      <ListItem button>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        {text && <ListItemText primary={text} />}
      </ListItem>
    </Link>
  )

  if (isOpen) return inner

  return <Tooltip title={text}>{inner}</Tooltip>
}

SidebarItem.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  text: PropTypes.string,
}
SidebarItem.defaultProps = {
  icon: null,
  text: null,
}

export default SidebarItem
