import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import MUILink from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import Rating from '@material-ui/lab/Rating'
import { Link } from 'react-router-dom'
import parseISODate from 'date-fns/parseISO'
import formatDate from 'date-fns/format'

import { parseError } from '../../lib/helpers'
import Title from '../Title'

const useStyles = makeStyles(theme => ({
  flexGrow: {
    flexGrow: 1,
  },
  table: {
    marginBottom: theme.spacing(2),
  },
  tabPanel: {
    padding: theme.spacing(2),
  },
  padding: {
    padding: theme.spacing(0, 2),
  },
}))

const ReviewsTable = ({ data }) => {
  // console.log(data)
  const classes = useStyles()

  return (
    <Table size="small" className={classes.table}>
      <TableBody>
        {data.map(c => {
          return (
            <TableRow key={c.id}>
              <TableCell>{formatDate(parseISODate(c.date), 'yyyy-MM-dd hh:ss')}</TableCell>
              <TableCell>{c.productId}</TableCell>
              <TableCell>
                <Box><Rating name="read-only" value={c.rating} readOnly /></Box>
                {c.headline}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}
ReviewsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const ReviewsWidget = () => {
  const classes = useStyles()
  const [isLoaded, setIsLoaded] = useState(true)
  const [error, setError] = useState(false)
  const [reviews, setReviews] = useState(null)
  const [total, setTotal] = useState(null)

  useEffect(() => {
    let didCancel = false

    const fetchReviews = async () => {
      try {
        const response = await axios.get(`/fetchReviews?verified=unapproved&page=1&limit=5`)
        // console.log(response.data)
        if (didCancel) return
        setReviews(response.data.reviews)
        setTotal(response.data.metadata[0]?.total ?? 0)
      } catch (e) {
        if (didCancel) return
        setError(parseError(e))
      } finally {
        if (!didCancel) {
          setIsLoaded(true)
        }
      }
    }

    fetchReviews()

    return () => {
      didCancel = true
    }
  }, [])

  if (!isLoaded) return <CircularProgress variant="indeterminate" />

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
      <Title>Unapproved Reviews ({total} total)</Title>
      {error && <Typography color="error">{error}</Typography>}
      {!error && isLoaded && (
        <>
          {reviews && (
            <div role="tabpanel" className={classes.tabPanel}>
              {reviews.length > 0 ? (
                <Typography gutterBottom>Showing five most recent unapproved reviews.</Typography>
              ) : (
                <Typography gutterBottom>No unapproved reviews to show.</Typography>
              )}
              <ReviewsTable data={reviews} />
            </div>
          )}
        </>
      )}

      <div className={classes.flexGrow} />

      <div className={classes.seeMore}>
        <MUILink component={Link} to="/reviews">
          More reviews
        </MUILink>
      </div>
    </Box>
  )
}

export default ReviewsWidget
