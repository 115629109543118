import React, { useState, useReducer } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
// import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'

import AddButton from './AddButton'
import CopyButton from './CopyButton'
import EditCommissionRow from './EditCommissionRow'
import CommissionRow from './CommissionRow'
import Pagination from '../../../Pagination'

import { getAuthorFullName } from '../../../../lib/helpers'

const useStyles = makeStyles(theme => ({
  tabs: {
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  tabPanelPaper: {
    margin: theme.spacing(2),
  },
  table: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(1),
  },
}))

const FILTER_ACTIVE = 'FILTER_ACTIVE'
const FILTER_AUTHOR = 'FILTER_AUTHOR'
const FILTER_PURCHASEOPTION = 'FILTER_PURCHASEOPTION'
const RESET_FILTERS = 'RESET_'

const initialFilters = { isActive: 'all', author: '', purchaseOption: '' }

const filtersReducer = (state, action) => {
  switch (action.type) {
    case FILTER_ACTIVE:
      return {
        ...state,
        isActive: action.payload,
      }
    case FILTER_AUTHOR:
      return {
        ...state,
        author: action.payload,
      }
    case FILTER_PURCHASEOPTION:
      return {
        ...state,
        purchaseOption: action.payload,
      }
    case RESET_FILTERS:
      return initialFilters
    default:
      return state
  }
}

const SettingsTab = ({
  authors,
  commissionPercentages,
  products,
  selectedYear,
  setCommissionPercentages,
}) => {
  // console.log(authors)
  // console.log(commissionPercentages)
  // console.log(products)

  const classes = useStyles()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [filters, filtersDispatch] = useReducer(filtersReducer, initialFilters)
  const [editId, setEditId] = useState(null)

  const totalPages = Math.ceil(parseInt(commissionPercentages.length, 10) / perPage)
  const filteredData = commissionPercentages
    .filter(c => {
      if (filters.isActive === 'all') return true
      return filters.isActive === 'yes' ? c.isActive : !c.isActive
    })
    .filter(c => {
      const authorName = getAuthorFullName(authors, c.authorSlug)
      const a = filters.author.trim().toLowerCase()

      return (
        authorName
          .trim()
          .toLowerCase()
          .indexOf(a) > -1 || c.authorSlug.indexOf(a) > -1
      )
    })
    .filter(c => {
      const po = filters.purchaseOption.trim().toLowerCase()
      if (po === '') return true

      const productObj = products.find(p => p.uid === c.purchaseOption)
      const productText = productObj ? productObj.data.product.data.title[0].text : c.purchaseOption
      const productDescriptionText = productObj ? productObj.data.description : null

      return (
        c.purchaseOption
          .trim()
          .toLowerCase()
          .indexOf(po) > -1 ||
        productText
          .trim()
          .toLowerCase()
          .indexOf(po) > -1 ||
        productDescriptionText
          .trim()
          .toLowerCase()
          .indexOf(po) > -1
      )
    })
  const visibleData = filteredData.slice((page - 1) * perPage, (page - 1) * perPage + perPage)

  return (
    <Paper className={clsx(classes.paper, classes.tabPanelPaper)} elevation={3}>
      <Box p={4}>
        <Typography variant="h5" component="h2" gutterBottom>
          {selectedYear} Ginger GM Author Commission Settings
        </Typography>
        {commissionPercentages.length > 0 ? (
          <>
            {totalPages > 1 && (
              <Pagination
                isDisabled={editId !== null}
                page={page}
                perPage={perPage}
                perPageMenuItems={[5, 10, 25, 50]}
                setPerPage={setPerPage}
                setPage={setPage}
                totalPages={totalPages}
              />
            )}
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Active?</TableCell>
                  <TableCell>Author</TableCell>
                  <TableCell>Purchase Option</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell align="right">Amount</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>
                    <Select
                      disabled={editId !== null}
                      value={filters.isActive}
                      onChange={e =>
                        filtersDispatch({ type: FILTER_ACTIVE, payload: e.target.value })
                      }
                    >
                      <MenuItem value="all">Show all</MenuItem>
                      <MenuItem value="yes">Active</MenuItem>
                      <MenuItem value="no">Not active</MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell>
                    <TextField
                      disabled={editId !== null}
                      value={filters.author}
                      onChange={e =>
                        filtersDispatch({ type: FILTER_AUTHOR, payload: e.target.value })
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      disabled={editId !== null}
                      value={filters.purchaseOption}
                      onChange={e =>
                        filtersDispatch({ type: FILTER_PURCHASEOPTION, payload: e.target.value })
                      }
                    />
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleData.map(c => {
                  if (editId === c._id) {
                    return (
                      <EditCommissionRow
                        key={c._id}
                        commission={c}
                        authors={authors}
                        products={products}
                        setCommissionPercentages={setCommissionPercentages}
                        year={selectedYear}
                        setEditId={setEditId}
                      />
                    )
                  }

                  return (
                    <CommissionRow
                      key={c._id}
                      commission={c}
                      authors={authors}
                      products={products}
                      setCommissionPercentages={setCommissionPercentages}
                      year={selectedYear}
                      setEditId={setEditId}
                      editId={editId}
                    />
                  )
                })}
              </TableBody>
            </Table>
          </>
        ) : (
          <Typography gutterBottom>
            No commissions found for {selectedYear}. You can copy commissions from another year.
          </Typography>
        )}
        <Box width="100%" display="flex" justifyContent="flex-end" mt={2}>
          {commissionPercentages.length === 0 && (
            <CopyButton
              className={classes.button}
              year={selectedYear}
              setCommissionPercentages={setCommissionPercentages}
            />
          )}
          <AddButton
            className={classes.button}
            isDisabled={editId !== null}
            setCommissionPercentages={setCommissionPercentages}
            year={selectedYear}
            authors={authors}
            products={products}
          />
        </Box>
      </Box>
    </Paper>
  )
}

SettingsTab.propTypes = {
  authors: PropTypes.arrayOf(PropTypes.object).isRequired,
  commissionPercentages: PropTypes.arrayOf(PropTypes.object).isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedYear: PropTypes.string.isRequired,
  setCommissionPercentages: PropTypes.func.isRequired,
}

export default SettingsTab
