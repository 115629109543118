import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: 'inherit',
    marginLeft: theme.spacing(0.5),
  },
}))

const ReceiptLink = ({ className, receiptURL }) => {
  const classes = useStyles()
  const [isReceiptLoading, setReceiptLoading] = useState(false)

  useEffect(() => {
    let didCancel = false

    const getReceiptHTML = async () => {
      if (!isReceiptLoading) return
      setReceiptLoading(true)

      const response = await axios.get(`/makeFoxyRequest?url=${receiptURL}`)
      var wnd = window.open('about:blank', '', '_blank')
      wnd.document.write(response.data)

      if (didCancel) return
      setReceiptLoading(false)
    }

    getReceiptHTML()

    return () => {
      didCancel = true
    }
  }, [isReceiptLoading, receiptURL])

  return (
    <Button
      className={className}
      disabled={isReceiptLoading}
      onClick={e => {
        e.stopPropagation()
        setReceiptLoading(true)
      }}
      size="small"
      variant="outlined"
    >
      view receipt
      <OpenInNewIcon className={classes.icon} />
    </Button>
  )
}

ReceiptLink.propTypes = {
  className: PropTypes.string,
  receiptURL: PropTypes.string.isRequired,
}
ReceiptLink.defaultProps = {
  className: null,
}

export default ReceiptLink
