import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import getMonth from 'date-fns/getMonth'
import getYear from 'date-fns/getYear'

const months = [
  { id: 11, title: 'December' },
  { id: 10, title: 'November' },
  { id: 9, title: 'October' },
  { id: 8, title: 'September' },
  { id: 7, title: 'August' },
  { id: 6, title: 'July' },
  { id: 5, title: 'June' },
  { id: 4, title: 'May' },
  { id: 3, title: 'April' },
  { id: 2, title: 'March' },
  { id: 1, title: 'February' },
  { id: 0, title: 'January' },
]

const years = [
  { id: 2025, title: '2025' },
  { id: 2024, title: '2024' },
  { id: 2023, title: '2023' },
  { id: 2022, title: '2022' },
  { id: 2021, title: '2021' },
  { id: 2020, title: '2020' },
  { id: 2019, title: '2019' },
  { id: 2018, title: '2018' },
]

const today = new Date()
const currentMonth = getMonth(today)
const currentYear = getYear(today)

const options = []
years.forEach(y => {
  months.forEach(m => {
    if (y.id > currentYear || (y.id === currentYear && m.id > currentMonth)) return
    options.push({
      value: `${m.id.toString().padStart(2, '0')}-${y.id.toString()}`,
      label: `${m.title} ${y.title}`,
    })
  })
})

const useStyles = makeStyles(theme => ({
  formControl: {
    // marginRight: theme.spacing(2),
    minWidth: 200,
  },
}))

const MonthSelect = ({ value, handleChange }) => {
  const classes = useStyles()

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="month-simple">Select Month</InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        inputProps={{
          name: 'month',
          id: 'month-simple',
        }}
      >
        {options.map(o => (
          <MenuItem key={o.value} value={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

MonthSelect.propTypes = {
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

export default MonthSelect
