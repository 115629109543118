import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import MUILink from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(0.5),
  },
}))

const ExternalLink = ({ href, linkText }) => {
  const classes = useStyles()

  return (
    <MUILink href={href} target="_blank" rel="noopener noreferrer">
      <Box component="span" display="inline-flex" alignItems="center">
        {linkText}
        <OpenInNewIcon className={classes.icon} fontSize="inherit" />
      </Box>
    </MUILink>
  )
}
ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
}

export default ExternalLink
