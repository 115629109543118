import React, { useState } from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { withSnackbar } from 'notistack'
import axios from 'axios'

import { parseError } from '../../lib/helpers'

const RefeedData = ({ className, enqueueSnackbar, webhookLink }) => {
  const [isSending, setIsSending] = useState(false)
  // const classes = useStyles()

  const handleRefeedWebhooks = async () => {
    setIsSending(true)

    try {
      const response = await axios.get(`/makeFoxyRequest?url=${webhookLink}&method=POST`)
      // console.log(response.data)
      enqueueSnackbar(response.data.message, { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(parseError(e), { variant: 'error' })
    } finally {
      setIsSending(false)
    }
  }

  return (
    <Button
      variant="outlined"
      onClick={handleRefeedWebhooks}
      disabled={isSending}
      className={className}
    >
      Refeed webhooks
    </Button>
  )
}

RefeedData.propTypes = {
  className: PropTypes.string,
  enqueueSnackbar: PropTypes.func.isRequired, // notistack
  webhookLink: PropTypes.string.isRequired,
}
RefeedData.defaultProps = {
  className: null,
}

export default withSnackbar(RefeedData)
