import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles(theme => ({
  textField: {
    minWidth: 260,
  },
}))

const NumberOfUsesPerCode = ({ nPerCode, onUpdate }) => {
  const classes = useStyles()
  const [value, setValue] = useState(nPerCode)

  let stringValue = parseInt(value, 10).toString()
  if (stringValue === '') {
    stringValue = '0'
  }

  return (
    <TextField
      className={classes.textField}
      helperText="Set to zero for unlimited"
      label="Number of uses allowed per code"
      inputProps={{
        min: 0,
        step: 1,
      }}
      type="number"
      value={stringValue}
      onChange={e => {
        setValue(e.target.value)
        onUpdate(e.target.value)
      }}
    />
  )
}

NumberOfUsesPerCode.propTypes = {
  nPerCode: PropTypes.number.isRequired,
  onUpdate: PropTypes.func.isRequired,
}

export default NumberOfUsesPerCode
