import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'

import AlertDialog from '../../../AlertDialog'

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 700,
  },
}))

const DeleteCouponCode = ({ coupon: c, deleteCouponCode }) => {
  const classes = useStyles()
  const [isConfirmDeleteOpen, setConfirmDeleteOpen] = useState(false)

  return (
    <>
      <IconButton onClick={() => setConfirmDeleteOpen(true)}>
        <DeleteIcon />
      </IconButton>
      <AlertDialog
        key={c.code}
        dialogContent={
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete the coupon code{' '}
              <span className={classes.bold}>{c.code}</span>?
            </DialogContentText>
          </DialogContent>
        }
        dialogTitle="Delete coupon code"
        handleClose={() => setConfirmDeleteOpen(false)}
        handleOk={() => deleteCouponCode(c._links.self.href)}
        isOpen={isConfirmDeleteOpen}
      />
    </>
  )
}

DeleteCouponCode.propTypes = {
  coupon: PropTypes.object.isRequired,
  deleteCouponCode: PropTypes.func.isRequired,
}

export default DeleteCouponCode
