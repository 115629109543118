import React from 'react'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableFooter from '@material-ui/core/TableFooter'

import ItemDetail from './ItemDetail'
import { GBP } from '../../../../lib/helpers'

const TableHeader = () => (
  <TableHead>
    <TableRow>
      <TableCell>Item</TableCell>
      <TableCell align="right">Shipping</TableCell>
      <TableCell align="right">Price</TableCell>
    </TableRow>
  </TableHead>
)

const MyTableFooter = ({
  total_item_price,
  applied_taxes,
  total_shipping,
  discounts,
  total_order,
}) => (
  <TableFooter>
    <TableRow>
      <TableCell colSpan={4} align="right">
        Subtotal: {GBP(total_item_price)}
      </TableCell>
    </TableRow>
    {applied_taxes.map(tax => (
      <TableRow key={tax.name}>
        <TableCell colSpan={4} align="right">
          {tax.name} ({tax.rate}%): {GBP(tax.amount)}
        </TableCell>
      </TableRow>
    ))}
    {total_shipping > 0 && (
      <TableRow>
        <TableCell colSpan={4} align="right">
          Shipping: {GBP(total_shipping)}
        </TableCell>
      </TableRow>
    )}
    {discounts.map(d => (
      <TableRow key={d.code}>
        <TableCell colSpan={4} align="right">
          {d.name} - {d.code}: {GBP(d.amount)}
        </TableCell>
      </TableRow>
    ))}
    <TableRow>
      <TableCell colSpan={4} align="right">
        Order Total: {GBP(total_order)}
      </TableCell>
    </TableRow>
  </TableFooter>
)

MyTableFooter.propTypes = {
  applied_taxes: PropTypes.array,
  discounts: PropTypes.array,
  total_item_price: PropTypes.number.isRequired,
  total_shipping: PropTypes.number.isRequired,
  total_order: PropTypes.number.isRequired,
}
MyTableFooter.defaultProps = {
  applied_taxes: [],
  discounts: [],
}

const ItemsTable = ({ transaction: t }) => (
  <Table size="small">
    <TableHeader />
    <TableBody>
      {t._embedded['fx:items'].map(i => (
        <ItemDetail key={i.code} item={i} hiddenColumns={['itemDiscount']} />
      ))}
    </TableBody>
    <MyTableFooter
      applied_taxes={t._embedded['fx:applied_taxes']}
      discounts={t._embedded['fx:discounts']}
      total_item_price={t.total_item_price}
      total_shipping={t.total_shipping}
      total_order={t.total_order}
    />
  </Table>
)

ItemsTable.propTypes = {
  transaction: PropTypes.object.isRequired,
}

export default ItemsTable
