import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import MUILink from '@material-ui/core/Link'
import CircularProgress from '@material-ui/core/CircularProgress'
import axios from 'axios'
import { Link } from 'react-router-dom'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'

import InfoButton from '../InfoButton'
import Title from '../Title'
import Pagination from '../Pagination'
import { GBP, parseError } from '../../lib/helpers'

const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}))

const TransactionsWidget = () => {
  const classes = useStyles()
  const [isLoaded, setIsLoaded] = useState(false)
  const [rows, setRows] = useState([])
  const [error, setError] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(5)
  const [totalPages, setTotalPages] = useState(1)
  const [refunds, setRefunds] = useState([])

  useEffect(() => {
    let didCancel = false

    const getOrders = async () => {
      setIsLoaded(false)
      setError(false)

      try {
        const response = await axios.get(
          `/makeFoxyRequest?url=https://api.foxycart.com/stores/22071/transactions?limit=${perPage}&offset=${(page -
            1) *
            perPage}&order=transaction_date desc&zoom=items`
        )
        const ids = response.data['_embedded']['fx:transactions'].map(t => t.id)
        const refundsResponse = await axios.get(`/fetchRefunds?transactionIds=${ids.join(',')}`)
        setRefunds(refundsResponse.data)

        if (didCancel) return
        setRows(response.data['_embedded']['fx:transactions'])
        if (didCancel) return
        setTotalPages(Math.ceil(parseInt(response.data.total_items, 10) / perPage))
      } catch (e) {
        if (didCancel) return
        setError(parseError(e))
      } finally {
        if (!didCancel) {
          setIsLoaded(true)
        }
      }
    }

    getOrders()

    return () => {
      didCancel = true
    }
  }, [page, perPage])

  const refundIds = refunds.map(r => r.transactionId)

  return (
    <>
      <Title>
        Recent Transactions
        <InfoButton
          tooltipContent={
            <>
              <Typography gutterBottom>Where does this data come from?</Typography>
              <Typography variant="body2">
                This is live data from FoxyCart, so new transactions should display here straight
                away.
              </Typography>
            </>
          }
        />
      </Title>
      {error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <>
          <Pagination
            isDisabled={!isLoaded}
            page={page}
            perPage={perPage}
            perPageMenuItems={[5, 10]}
            setPerPage={setPerPage}
            setPage={setPage}
            totalPages={totalPages}
          />
          {isLoaded ? (
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Transaction Id</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Items</TableCell>
                  <TableCell align="right">Order Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(row => {
                  const items = row['_embedded']['fx:items'].map(i => i.code).join(', ')
                  return (
                    <TableRow key={row.id}>
                      <TableCell>
                        {row.total_shipping > 0 && <LocalShippingIcon className={classes.icon} />}
                        {refundIds.includes(row.id) && <MoneyOffIcon className={classes.icon} />}
                      </TableCell>
                      <TableCell>
                        <MUILink component={Link} to={`/transactions/${row.id}`}>
                          {row.id}
                        </MUILink>
                      </TableCell>
                      <TableCell>
                        {format(parseISO(row.transaction_date), 'yyyy-MM-dd HH:mm')}
                      </TableCell>
                      <TableCell>
                        {row.customer_first_name} {row.customer_last_name}
                      </TableCell>
                      <TableCell align="right">{items}</TableCell>
                      <TableCell align="right">{GBP(row.total_order)}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          ) : (
            <CircularProgress variant="indeterminate" />
          )}
        </>
      )}
      <div className={classes.seeMore}>
        <MUILink component={Link} to="/transactions">
          More transactions
        </MUILink>
      </div>
    </>
  )
}

export default TransactionsWidget
