import React from 'react'
import PropTypes from 'prop-types'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

import { GBP } from '../../../../../lib/helpers'

const styles = StyleSheet.create({
  container: {
    borderTopColor: '#eee',
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    fontSize: 12,
    lineHeight: 1.33,
    marginBottom: 10,
    marginTop: 10,
    paddingBottom: 10,
    paddingTop: 10,
  },
  smallText: {
    fontSize: 10,
  },
  row: {
    flexDirection: 'row',
    fontSize: 10,
    justifyContent: 'space-between',
    marginVertical: 4,
    width: '100%',
  },
  column1: {
    padding: 4,
    width: 104,
  },
  column2: {
    flex: 1,
    padding: 4,
  },
  column3: {
    padding: 4,
    width: 200,
  },
})

const Table = ({ commissionPercentage, reportData }) => {
  return (
    <View style={styles.container}>
      {reportData.map(row => {
        const totalDiscounts = row.discounts
          ? row.discounts.reduce((sum, d) => sum + d.amount, 0)
          : 0

        const total = row.price * row.quantity + totalDiscounts

        return (
          <View key={`${row.id}-${row.code}`} style={styles.row} wrap={false}>
            <View style={styles.column1}>
              <Text>{format(parseISO(row.transaction_date), 'yyyy-MM-dd HH:mm')}</Text>
              <Text>#{row.id}</Text>
            </View>
            <View style={styles.column2}>
              <Text style={{ fontSize: 12 }}>{row.name}</Text>
              {row.selection && <Text style={styles.smallText}>Selection: {row.selection}</Text>}
              <Text style={styles.smallText}>SKU: {row.code}</Text>
            </View>
            <View style={styles.column3}>
              <View style={{ flexDirection: 'row', fontSize: 10 }}>
                <Text
                  style={{
                    width: 136,
                  }}
                >
                  Quantity:
                </Text>
                <Text
                  style={{
                    width: 58,
                    textAlign: 'right',
                  }}
                >
                  {row.quantity}
                </Text>
              </View>
              <View style={{ flexDirection: 'row', fontSize: 10 }}>
                <Text style={{ width: 136 }}>Price:</Text>
                <Text style={{ width: 58, textAlign: 'right' }}>{GBP(row.price)}</Text>
              </View>
              <View style={{ flexDirection: 'row', fontSize: 10 }}>
                <Text style={{ width: 136 }}>Subtotal:</Text>
                <Text style={{ width: 58, textAlign: 'right' }}>
                  {GBP(row.price * row.quantity)}
                </Text>
              </View>
              {row.discounts.map(d => (
                <View key={d.coupon} style={{ flexDirection: 'row', fontSize: 10 }}>
                  <Text style={{ width: 136 }}>{d.coupon}:</Text>
                  <Text style={{ width: 58, textAlign: 'right' }}>{GBP(d.amount)}</Text>
                </View>
              ))}
              <View style={{ flexDirection: 'row', fontSize: 10 }}>
                <Text style={{ width: 136 }}>Total:</Text>
                <Text style={{ width: 58, textAlign: 'right' }}>{GBP(total)}</Text>
              </View>
              <View style={{ flexDirection: 'row', fontSize: 12 }}>
                <Text style={{ width: 136, fontWeight: 700 }}>
                  Commission @ {commissionPercentage}%:
                </Text>
                <Text style={{ width: 58, textAlign: 'right', fontWeight: 700 }}>
                  {GBP((total * commissionPercentage) / 100)}
                </Text>
              </View>
            </View>
          </View>
        )
      })}
    </View>
  )
}

Table.propTypes = {
  commissionPercentage: PropTypes.number.isRequired,
  reportData: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Table
