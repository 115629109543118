import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#4183c4',
    },
    secondary: {
      main: 'rgb(255, 102, 0)',
    },
  },
  overrides: {
    MuiLink: {
      underlineHover: {
        textDecoration: 'underline',
      },
    },
  },
})

export default theme
