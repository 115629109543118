import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import getMonth from 'date-fns/getMonth'
import getYear from 'date-fns/getYear'
import axios from 'axios'
import sortBy from 'lodash/sortBy'
import { withSnackbar } from 'notistack'

import ReportTab from './ReportTab'
import SettingsTab from './SettingsTab'
import Layout from '../../Layout'
import { parseError } from '../../../lib/helpers'

const today = new Date()
const currentMonth = getMonth(today)
const currentYear = getYear(today)
const initialMonth = `${currentMonth.toString().padStart(2, '0')}-${currentYear.toString()}`

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  tabs: {
    marginBottom: theme.spacing(2),
  },
}))

const IChessReport = ({ enqueueSnackbar }) => {
  const classes = useStyles()
  const [selectedMonth, setMonth] = useState(initialMonth)
  const [error, setError] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isSettingsLoaded, setIsSettingsLoaded] = useState(false)
  const [reportData, setReportData] = useState([])
  const [tabValue, setTabValue] = useState(0)
  const [commissionPercentage, setCommissionPercentage] = useState(null)

  useEffect(() => {
    let didCancel = false

    const fetchSettings = async () => {
      setIsSettingsLoaded(false)

      try {
        const response = await axios.get(`/iChessReportSettings`)
        // console.log(response.data)
        if (didCancel) return
        setCommissionPercentage(response.data.commissionPercentage)
      } catch (e) {
        if (didCancel) return
        setError(parseError(e))
      } finally {
        if (!didCancel) {
          setIsSettingsLoaded(true)
        }
      }
    }

    fetchSettings()

    return () => {
      didCancel = true
    }
  }, [])

  useEffect(() => {
    let didCancel = false

    const fetchReport = async () => {
      setError(false)
      setIsLoaded(false)
      setReportData([])
      try {
        const response = await axios.get(`/fetchIChessReport?reportMonth=${selectedMonth}`)
        // console.log(response.data)

        // calc the discounts for each item
        const itemsWithDiscounts = []
        /* eslint-disable no-unused-vars */
        for (let item of response.data) {
          /* eslint-enable no-unused-vars */
          if (!item.discounts) {
            itemsWithDiscounts.push({
              ...item,
              discounts: [],
            })
          } else {
            const discountResponse = await axios.get(`/calcItemDiscount?transactionId=${item.id}`)
            // console.log(discountResponse.data)
            const itemDiscounts = discountResponse.data.find(d => d.itemCode === item.code)
            itemsWithDiscounts.push({
              ...item,
              discounts: itemDiscounts.discounts,
            })
          }
        }

        if (didCancel) return
        setReportData(sortBy(itemsWithDiscounts, 'transaction_date').reverse())
      } catch (e) {
        if (didCancel) return
        setError(parseError(e))
      } finally {
        if (!didCancel) {
          setIsLoaded(true)
        }
      }
    }

    fetchReport()

    return () => {
      didCancel = true
    }
  }, [selectedMonth])

  const handleCommissionPercentageChange = async value => {
    try {
      // Save in db
      await axios.get(`/iChessReportSettings?action=update&commissionPercentage=${value}`)
      // console.log(response.data)
      setCommissionPercentage(parseInt(value, 10))
    } catch (e) {
      enqueueSnackbar(`Save commission percentage failed - ${parseError(e)}`, {
        variant: 'error',
      })
    }
  }

  return (
    <Layout title="iChess Commissions Report">
      <Paper className={classes.paper}>
        <Tabs
          className={classes.tabs}
          value={tabValue}
          onChange={(e, newValue) => setTabValue(newValue)}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Report" />
          <Tab label="Settings" />
        </Tabs>

        {isSettingsLoaded ? (
          <>
            <Box hidden={tabValue !== 0}>
              <ReportTab
                commissionPercentage={parseInt(commissionPercentage, 10)}
                error={error}
                isLoaded={isLoaded}
                reportData={reportData}
                selectedMonth={selectedMonth}
                setMonth={setMonth}
              />
            </Box>

            <Box hidden={tabValue !== 1}>
              <SettingsTab
                commissionPercentage={parseInt(commissionPercentage, 10)}
                setCommissionPercentage={handleCommissionPercentageChange}
              />
            </Box>
          </>
        ) : (
          <>{error && <Typography color="error">{error}</Typography>}</>
        )}
      </Paper>
    </Layout>
  )
}

IChessReport.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
}

export default withSnackbar(IChessReport)
