import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import getQuarter from 'date-fns/getQuarter'
import getYear from 'date-fns/getYear'
import sortBy from 'lodash/sortBy'

const quarters = [
  { id: 1, title: 'Quarter 1' },
  { id: 2, title: 'Quarter 2' },
  { id: 3, title: 'Quarter 3' },
  { id: 4, title: 'Quarter 4' },
]

const years = [
  { id: 2022, title: '2022' },
  { id: 2021, title: '2021' },
  { id: 2020, title: '2020' },
  { id: 2019, title: '2019' },
  { id: 2018, title: '2018' },
]

const today = new Date()
const currentQuarter = getQuarter(today)
const currentYear = getYear(today)

const options = []
years.forEach(y => {
  quarters.forEach(q => {
    if (y.id > currentYear || (y.id === currentYear && q.id > currentQuarter)) return
    options.push({
      value: `${y.id.toString()}-${q.id}`,
      label: `${q.title} ${y.title}`,
    })
  })
})

const useStyles = makeStyles(theme => ({
  formControl: {
    // marginRight: theme.spacing(2),
    minWidth: 200,
  },
}))

const QuarterSelect = ({ value, handleChange }) => {
  const classes = useStyles()

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="quarter-simple">Select Quarter</InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        inputProps={{
          name: 'quarter',
          id: 'quarter-simple',
        }}
      >
        {sortBy(options, 'value')
          .reverse()
          .map(o => (
            <MenuItem key={o.value} value={o.value}>
              {o.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}

QuarterSelect.propTypes = {
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

export default QuarterSelect
