import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import getYear from 'date-fns/getYear'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import axios from 'axios'

import Layout from '../../Layout'
import ReportTab from './ReportTab'
import SettingsTab from './SettingsTab'
import { parseError } from '../../../lib/helpers'
import YearSelect from '../YearSelect'
import MonthSelect from './MonthSelect'

const today = new Date()
const currentYear = getYear(today).toString()

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  tabs: {
    marginBottom: theme.spacing(2),
  },
  headerTable: {
    margin: theme.spacing(4, 0),
  },
  actions: {
    marginBottom: theme.spacing(4),
  },
}))

const CommissionsReport = () => {
  const classes = useStyles()
  const [year, setYear] = useState(currentYear)
  const [month, setMonth] = useState('all')
  const [tabValue, setTabValue] = useState(0)
  const [isSettingsLoaded, setIsSettingsLoaded] = useState(false)
  const [error, setError] = useState(false)
  const [commissionPercentages, setCommissionPercentages] = useState([])
  const [authors, setAuthors] = useState([])
  const [products, setProducts] = useState([])
  const [authorSlug, setAuthorSlug] = useState('select')

  useEffect(() => {
    let didCancel = false

    const fetchSettings = async () => {
      setIsSettingsLoaded(false)

      try {
        const settingsPromises = [
          axios.get(`/commissionsReportSettings?year=${year}`),
          axios.get(`/fetchAuthors`),
          axios.get(`/fetchProducts`),
        ]
        const [commissionsResponse, authorsResponse, productsResponse] = await Promise.all(
          settingsPromises
        )
        if (didCancel) return
        setCommissionPercentages(commissionsResponse.data)
        setAuthors(authorsResponse.data)
        setProducts(productsResponse.data)
      } catch (e) {
        if (didCancel) return
        setError(parseError(e))
      } finally {
        if (!didCancel) {
          setIsSettingsLoaded(true)
        }
      }
    }

    fetchSettings()

    return () => {
      didCancel = true
    }
  }, [year])

  return (
    <Layout title="Author Commissions Report">
      <Box display="flex" justifyContent="center" mb={4}>
        <YearSelect
          value={year.toString()}
          handleChange={e => {
            setAuthorSlug('select')
            setYear(e.target.value)
          }}
        />
        <Box ml={2}>
          <MonthSelect
            value={month.toString()}
            handleChange={e => {
              setMonth(e.target.value)
            }}
          />
        </Box>
      </Box>

      <Paper className={classes.paper}>
        <Tabs
          className={classes.tabs}
          value={tabValue}
          onChange={(e, newValue) => setTabValue(newValue)}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Report" />
          <Tab label="Settings" />
        </Tabs>

        {isSettingsLoaded && !error ? (
          <>
            <Box hidden={tabValue !== 0}>
              <ReportTab
                authorSlug={authorSlug}
                setAuthorSlug={setAuthorSlug}
                authors={authors}
                commissionPercentages={commissionPercentages.filter(c => c.isActive)}
                selectedYear={year}
                selectedMonth={month.toString()}
              />
            </Box>

            <Box hidden={tabValue !== 1}>
              <SettingsTab
                commissionPercentages={commissionPercentages}
                selectedYear={year}
                authors={authors}
                products={products}
                setCommissionPercentages={setCommissionPercentages}
              />
            </Box>
          </>
        ) : (
          <>{error && <Typography color="error">{error}</Typography>}</>
        )}
      </Paper>
    </Layout>
  )
}
export default CommissionsReport
