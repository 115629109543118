import React from 'react'
import PropTypes from 'prop-types'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import sortBy from 'lodash/sortBy'

import { getAuthorFullName } from '../../../lib/helpers'

const AuthorSelect = ({ className, value, handleChange, authors, hideInputLabel }) => {
  const sortedAuthors = sortBy(authors, 'uid')

  return (
    <>
      {!hideInputLabel && <InputLabel htmlFor="author-simple">Author</InputLabel>}
      <Select
        className={className}
        value={value}
        onChange={handleChange}
        inputProps={{
          name: 'author',
          id: 'author-simple',
        }}
      >
        <MenuItem value="select">Select an author</MenuItem>
        {sortedAuthors.map(a => (
          <MenuItem key={a.uid} value={a.uid}>
            {getAuthorFullName(authors, a.uid)}
          </MenuItem>
        ))}
      </Select>
    </>
  )
}

AuthorSelect.propTypes = {
  authors: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  hideInputLabel: PropTypes.bool,
}
AuthorSelect.defaultProps = {
  className: null,
  hideInputLabel: false,
}

export default AuthorSelect
