import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import MUILink from '@material-ui/core/Link'
import { Link } from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Box from '@material-ui/core/Box'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'

import ReceiptLink from '../ReceiptLink'
import { GBP, getItemCategoryText } from '../../../lib/helpers'

const useStyles = makeStyles(() => ({
  link: {
    cursor: 'pointer',
  },
  shippingIcon: {
    marginTop: 6,
  },
}))

const ItemsList = ({ items }) => {
  const classes = useStyles()
  return (
    <List dense disablePadding>
      {items.map(item => {
        const category = item._embedded['fx:item_category']
          ? getItemCategoryText(item._embedded['fx:item_category'].code)
          : null
        return (
          <ListItem key={item.code} disableGutters>
            {category && (
              <ListItemText>
                {category}: {item.name}
              </ListItemText>
            )}
            {item.weight > 0 && (
              <ListItemSecondaryAction>
                <LocalShippingIcon className={classes.shippingIcon} />
              </ListItemSecondaryAction>
            )}
          </ListItem>
        )
      })}
    </List>
  )
}

ItemsList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const Row = ({ transaction: t }) => {
  const classes = useStyles()

  return (
    <>
      <TableRow>
        <TableCell>
          <MUILink component={Link} to={`/transactions/${t.id}`} className={classes.link}>
            {t.id}
          </MUILink>
        </TableCell>
        <TableCell>
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <span>{format(parseISO(t.transaction_date), 'E dd LLL yyyy HH:mm')} </span>
            <ReceiptLink receiptURL={t._links['fx:receipt'].href} />
          </Box>
        </TableCell>
        <TableCell>
          <Box display="flex">
            <ItemsList items={t._embedded['fx:items']} />
          </Box>
        </TableCell>
        <TableCell align="right">{GBP(t.total_order)}</TableCell>
        <TableCell>
          {t.customer_first_name} {t.customer_last_name}
        </TableCell>
      </TableRow>
    </>
  )
}

Row.propTypes = {
  transaction: PropTypes.object.isRequired,
}

export default Row
