import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'

import CouponDates from './CouponDates'
import CouponName from './CouponName'
import CouponDiscounts from './CouponDiscounts'

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
}))

const Details = ({ coupon: c, couponId }) => {
  const classes = useStyles()

  return (
    <>
      <Paper elevation={3} className={classes.paper}>
        <CouponName couponId={couponId} name={c.name} />
      </Paper>
      <Paper elevation={3} className={classes.paper}>
        <CouponDates couponId={couponId} start_date={c.start_date} end_date={c.end_date} />
      </Paper>
      <Paper elevation={3} className={classes.paper}>
        <CouponDiscounts
          couponId={couponId}
          coupon_discount_type={c.coupon_discount_type}
          coupon_discount_details={c.coupon_discount_details}
        />
      </Paper>
    </>
  )
}

Details.propTypes = {
  coupon: PropTypes.object.isRequired,
  couponId: PropTypes.string.isRequired,
}

export default Details
