import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import MUILink from '@material-ui/core/Link'
import { Link } from 'react-router-dom'

import Pagination from '../../../Pagination'
import { parseError } from '../../../../lib/helpers'

const Row = ({ transactionId, date_created }) => (
  <ListItem disableGutters>
    <ListItemText>
      <MUILink component={Link} to={`/transactions/${transactionId}`}>
        {transactionId}
      </MUILink>
    </ListItemText>
    {date_created && (
      <ListItemSecondaryAction>
        {format(parseISO(date_created), 'yyyy-MM-dd HH:mm')}
      </ListItemSecondaryAction>
    )}
  </ListItem>
)

Row.propTypes = {
  date_created: PropTypes.string,
  transactionId: PropTypes.string.isRequired,
}
Row.defaultProps = {
  date_created: null,
}

const TransactionsDialog = ({ isOpen, handleClose, couponCode }) => {
  const [transactions, setTransactions] = useState([])
  const [error, setError] = useState(null)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(5)
  const [totalPages, setTotalPages] = useState(1)
  const [isLoaded, setLoaded] = useState(false)

  useEffect(() => {
    if (!isOpen) return // only fetch once, when the dialog opens, not on mount
    setLoaded(false)
    setError(false)

    let didCancel = false

    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `/makeFoxyRequest?url=${
            couponCode._links['fx:coupon_code_transactions'].href
          }&limit=${perPage}&offset=${(page - 1) * perPage}&order=date_created desc`
        )
        // console.log(response.data)
        if (didCancel) return
        setTotalPages(Math.ceil(parseInt(response.data.total_items, 10) / perPage))
        setTransactions(response.data._embedded['fx:coupon_code_transactions'])
      } catch (e) {
        if (didCancel) return
        setError(parseError(e))
        setTransactions([])
      } finally {
        if (!didCancel) {
          setLoaded(true)
        }
      }
    }

    fetchCategories()

    return () => {
      didCancel = true
    }
  }, [couponCode, isOpen, page, perPage])

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth>
      <DialogTitle>Transactions for coupon code {couponCode.code}</DialogTitle>
      <DialogContent>
        {error && (
          <Typography color="error" gutterBottom>
            {error}
          </Typography>
        )}
        {!isLoaded && <CircularProgress />}
        {transactions && transactions.length > 0 && isLoaded && (
          <>
            <Pagination
              isDisabled={!isLoaded}
              page={page}
              perPage={perPage}
              perPageMenuItems={[5, 10]}
              setPerPage={setPerPage}
              setPage={setPage}
              totalPages={totalPages}
            />
            <List disablePadding>
              {transactions.map(t => {
                const { href } = t._links['fx:transaction']
                const transactionId = href.substring(href.lastIndexOf('/') + 1)

                return (
                  <Row key={href} transactionId={transactionId} date_created={t.date_created} />
                )
              })}
            </List>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

TransactionsDialog.propTypes = {
  couponCode: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default TransactionsDialog
