import React from 'react'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'

const CouponName = ({ name, setName }) => {
  return (
    <TextField
      id="name"
      error={name.length > 50}
      fullWidth
      helperText="Enter a name for the coupon. Max 50 characters."
      required
      label="Coupon name"
      value={name}
      onChange={e => setName(e.target.value)}
    />
  )
}

CouponName.propTypes = {
  name: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,
}

export default CouponName
