import React from 'react'
import PropTypes from 'prop-types'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import PeopleIcon from '@material-ui/icons/People'
import AssignmentIcon from '@material-ui/icons/Assignment'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import VideocamIcon from '@material-ui/icons/Videocam'
import StarIcon from '@material-ui/icons/Star'

import SidebarItem from './SidebarItem'

const MainListItems = ({ isOpen }) => {
  return (
    <>
      <SidebarItem href="/" icon={<DashboardIcon />} text="Dashboard" isOpen={isOpen} />
      <SidebarItem
        href="/transactions"
        icon={<AttachMoneyIcon />}
        text="Transactions"
        isOpen={isOpen}
      />
      <SidebarItem href="/customers" icon={<PeopleIcon />} text="Customers" isOpen={isOpen} />
      <SidebarItem href="/coupons" icon={<ShoppingCartIcon />} text="Coupons" isOpen={isOpen} />
      <SidebarItem href="/reviews" icon={<StarIcon />} text="Reviews" isOpen={isOpen} />
      <SidebarItem href="/videos" icon={<VideocamIcon />} text="Videos" isOpen={isOpen} />
      <SidebarItem href="/reports" icon={<AssignmentIcon />} text="Reports" isOpen={isOpen} />
    </>
  )
}

MainListItems.propTypes = {
  isOpen: PropTypes.bool.isRequired,
}

export default MainListItems
