// TODO: ref https://react-pdf.org/repl
import React from 'react'
import PropTypes from 'prop-types'
import { Document, Page, View, Text } from '@react-pdf/renderer'

import { getReportTitle } from '../../helpers'
import globalStyles from '../../../PDFs/styles'
import PDFHeader from '../../../PDFs/PDFHeader'
import PDFFooter from '../../../PDFs/PDFFooter'
import Table from './Table'
import Totals from './Totals'

const PDFReport = ({ commissionPercentage, reportData, selectedMonth }) => {
  const totalSalesPrice = reportData.reduce((sum, i) => {
    const totalDiscounts = i.discounts ? i.discounts.reduce((sum, d) => sum + d.amount, 0) : 0
    return sum + (i.price * i.quantity + totalDiscounts)
  }, 0)

  return (
    <Document
      title={`Ginger GM iChess Commissions ${getReportTitle(selectedMonth, '')}`}
      author="Ginger GM"
    >
      <Page style={globalStyles.body} size="A4">
        <PDFHeader title="iChess Commissions" subtitle={getReportTitle(selectedMonth, '')} />
        <Totals
          totalSalesPrice={totalSalesPrice}
          itemCount={reportData.length}
          commissionPercentage={commissionPercentage}
        />
        <View
          style={{
            borderTopColor: '#eee',
            borderTopStyle: 'solid',
            borderTopWidth: 1,
            marginTop: 10,
            paddingTop: 10,
            fontSize: 12,
          }}
        >
          <Text>Notes:</Text>
          <Text style={{ marginTop: 10, paddingLeft: 10 }}>
            - If a coupon applies to more than one product in an order, the calculated discount per
            product has been weighted according to the base prices of those products.
          </Text>
        </View>
        <Table commissionPercentage={commissionPercentage} reportData={reportData} />
        <PDFFooter />
      </Page>
    </Document>
  )
}

PDFReport.propTypes = {
  commissionPercentage: PropTypes.number.isRequired,
  reportData: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedMonth: PropTypes.string.isRequired,
}

export default PDFReport
