import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

const EditButton = ({ id, onClick, isDisabled }) => {
  // console.log(c)

  return (
    <IconButton aria-label="edit" onClick={() => onClick(id)} disabled={isDisabled}>
      <EditIcon />
    </IconButton>
  )
}

EditButton.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
}

export default EditButton
