import React from 'react'
import Box from '@material-ui/core/Box'

import ExternalLink from '../ExternalLink'

const RTFM = () => (
  <Box display="flex" justifyContent="center" mb={2} width="100%">
    <ExternalLink href="https://wiki.foxycart.com/v/2.0/coupons_and_discounts" linkText="RTFM" />
  </Box>
)

export default RTFM
