import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import netlifyIdentity from 'netlify-identity-widget'

import logo from '../ggm-logo@2x.png'

const useStyles = makeStyles(theme => ({
  logo: {
    marginBottom: theme.spacing(2),
    width: 360,
  },
}))

const Login = () => {
  const classes = useStyles()

  return (
    <Box alignItems="center" display="flex" flexDirection="column" width="100%">
      <img src={logo} alt="Ginger GM logo" className={classes.logo} />
      <Button color="primary" onClick={() => netlifyIdentity.open()} variant="outlined">
        Login
      </Button>
    </Box>
  )
}

export default Login
