import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import axios from 'axios'
import ExternalLink from '../../ExternalLink'

import ResendEmailsLink from '../ResendEmailsLink'
import ReceiptLink from '../ReceiptLink'
import RefeedData from '../RefeedData'

const useStyles = makeStyles(theme => ({
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
}))

const Header = ({ transaction: t }) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(true)
  const [hasFetchAppOrder, setFetchAppOrder] = useState(null)
  const [downloadLink, setDownloadLink] = useState(null)

  useEffect(() => {
    let didCancel = false

    // Check if a FetchApp order exists. If there are no downloads, there won't be one.
    const getFetchAppOrder = async () => {
      try {
        const response = await axios.get(`/makeFetchappRequest?url=/orders/${t.id}?method=GET`)
        // get fetchapp downloads link
        const dl = response.data.elements[0].elements.find(e => e.name === 'link')
        // console.log(dl)
        if (dl) {
          setDownloadLink(`http://gingergm.fetchapp.com${dl.elements[0].text}`)
        }
        if (didCancel) return
        setFetchAppOrder(true)
      } catch (e) {
        setFetchAppOrder(false)
      } finally {
        if (didCancel) return
        setIsLoading(false)
      }
    }

    getFetchAppOrder()

    return () => {
      didCancel = true
    }
  }, [t.id])

  return (
    <Box display="flex" width="100%">
      <Box display="flex" flexDirection="column" flex={1} alignItems="flex-start">
        <Typography variant="h5" component="h2" className={classes.marginBottom}>
          {format(parseISO(t.transaction_date), 'EEE MMM dd yyyy HH:mm')}
        </Typography>
        <Typography variant="h6" component="h3" className={classes.marginBottom}>
          {t.customer_first_name} {t.customer_last_name} ({t.customer_email}){' '}
        </Typography>
        {downloadLink && (
          <Typography variant="h6" component="h3">
            <ExternalLink href={downloadLink} linkText="FetchApp downloads" />
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection="column" alignItems="flex-end">
        <ReceiptLink className={classes.marginBottom} receiptURL={t['_links']['fx:receipt'].href} />
        <ResendEmailsLink
          className={classes.marginBottom}
          isLoading={isLoading}
          hasFetchAppOrder={hasFetchAppOrder}
          resendEmailsLink={t._links['fx:send_emails'].href}
          transactionId={t.id}
        />
        <RefeedData webhookLink={t._links['fx:process_webhook'].href} />
      </Box>
    </Box>
  )
}

Header.propTypes = {
  transaction: PropTypes.object.isRequired,
}

export default Header
