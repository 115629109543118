import React from 'react'
import {
  Box,
  Checkbox,
  Typography,
  CircularProgress,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  List,
  ListItem,
  ListItemText,
  Switch,
  FormControlLabel,
} from '@material-ui/core'
import { Rating } from '@material-ui/lab'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import { useSnackbar } from 'notistack'
import formatDate from 'date-fns/format'
import parseISODate from 'date-fns/parseISO'
import sortBy from 'lodash/sortBy'

import Layout from '../Layout'
import Pagination from '../Pagination'
import { parseError } from '../../lib/helpers'
import UserContext from '../../UserContext'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  tabPanel: {
    padding: theme.spacing(2),
  },
  alignRight: {
    textAlign: 'right',
  },
}))

const Reviews = () => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const [reviews, setReviews] = React.useState([])
  const [status, setStatus] = React.useState('fetching')
  const [error, setError] = React.useState(false)
  const [page, setPage] = React.useState(1)
  const [limit, setLimit] = React.useState(10)
  const [total, setTotal] = React.useState(null)
  const [products, setProducts] = React.useState([])
  const [approvedFilter, setApprovedFilter] = React.useState('unapproved') // all || approved || unapproved
  const user = React.useContext(UserContext)

  // Load products, just for display purposes
  React.useEffect(() => {
    let didCancel = false

    const fetchProducts = async () => {
      try {
        setStatus('fetching')

        const response = await axios.get(`/fetchProducts`)
        // console.log(response.data)
        if (didCancel) return
        setProducts(response.data.map(p => ({
          slug: p.data.product.slug,
          title: p.data.product.data?.title[0].text
        })))
      } catch (e) {
        if (didCancel) return
        setError(parseError(e))
      } finally {
        if (!didCancel) {
          setStatus('idle')
        }
      }
    }

    fetchProducts()

    return () => {
      didCancel = true
    }
  }, [])

  React.useEffect(() => {
    let didCancel = false

    const fetchReviews = async () => {
      try {
        setStatus('fetching')

        const response = await axios.get(`/fetchReviews?verified=${approvedFilter}&page=${page}&limit=${limit}`)
        // console.log(response.data)
        if (didCancel) return
        setReviews(response.data.reviews)
        setTotal(response.data.metadata[0]?.total ?? 0)
      } catch (e) {
        if (didCancel) return
        setError(parseError(e))
      } finally {
        if (!didCancel) {
          setStatus('idle')
        }
      }
    }

    fetchReviews()

    return () => {
      didCancel = true
    }
  }, [limit, page, user, approvedFilter])

  const handleToggleApproved = id => async e => {
    try {
      const isChecked = e.target.checked

      await axios.get(`/updateReview?id=${id}&verified=${isChecked.toString()}&user=${user.email}`)
      enqueueSnackbar('Review updated.', { variant: 'success' })
      // update local ui
      setReviews(reviews =>
        reviews.map(r => {
          if (r.id === id) {
            return {
              ...r,
              verified: isChecked,
            }
          }
          return r
        })
      )
    } catch (e) {
      enqueueSnackbar('Unable to update review.', { variant: 'error' })
    }
  }

  const handleApprovedChange = e => {
    if (e.target.checked && approvedFilter === 'unapproved') {
      setApprovedFilter('all')
      return
    }

    if (!e.target.checked && approvedFilter === 'all') {
      setApprovedFilter('unapproved')
      return
    }
  }

  const handleUnapprovedChange = e => {
    if (e.target.checked && approvedFilter === 'approved') {
      setApprovedFilter('all')
      return
    }

    if (!e.target.checked && approvedFilter === 'all') {
      setApprovedFilter('approved')
      return
    }
  }

  if (!products) return null // Wait for products to load
  // console.log(products)

  return (
    <Layout title="Reviews">
      <Paper className={classes.paper}>
        <Typography variant="h5" component="h2" gutterBottom>
          Reviews
        </Typography>
        <Typography gutterBottom>Approved reviews are displayed on the website.</Typography>
        {error && <Typography color="error">{error}</Typography>}
        <Box my={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={approvedFilter !== 'approved'}
                onChange={handleUnapprovedChange}
                name="show-unapproved"
                color="primary"
              />
            }
            label="Show unapproved reviews"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={approvedFilter !== 'unapproved'}
                onChange={handleApprovedChange}
                name="show-approved"
                color="primary"
              />
            }
            label="Show approved reviews"
          />
        </Box>
        {status === 'fetching' && <CircularProgress />}
        {status === 'idle' && total > 0 && (
          <Paper className={classes.paper}>
            <Pagination
              isDisabled={status === 'fetching'}
              page={page}
              perPage={limit}
              perPageMenuItems={[5, 10, 25]}
              setPerPage={x => setLimit(x)}
              setPage={x => setPage(x)}
              totalPages={Math.ceil(total / limit)}
            />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>User</TableCell>
                  <TableCell>Review</TableCell>
                  <TableCell>History</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reviews.map(r => {
                  let product = products.find(p => r.productId === p.slug)
                  let title = product?.title ?? r.productId
                  return (
                    <TableRow key={r.id}>
                      <TableCell>
                        <List dense disablePadding>
                          <ListItem>
                            {formatDate(parseISODate(r.date), 'yyyy-MM-dd HH:mm')}
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary={`${r.user.firstName} ${r.user.lastName}`}
                              secondary={r.user.email || null}
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="Rating" secondary={r.user.rating || null} />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="City" secondary={r.user.city || null} />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="Country" secondary={r.user.country || null} />
                          </ListItem>
                        </List>
                      </TableCell>
                      <TableCell>
                        <Box mb={2}>
                          <Typography variant="h5" gutterBottom>
                            {title}
                          </Typography>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={r.verified === true}
                                onChange={handleToggleApproved(r.id)}
                                name={`approve-review-${r.id}`}
                                color="primary"
                              />
                            }
                            label="Approved"
                          />
                        </Box>
                        <Box border="1px solid #eee" p={2}>
                          <Rating name="read-only" value={r.rating} readOnly />
                          <Typography variant="h6" gutterBottom>
                            {r.headline}
                          </Typography>
                          <Typography variant="body2">{r.review}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Table size="small">
                          <TableBody>
                            {sortBy(r.updates, 'updatedAt').reverse().map((u, i) => (
                              <TableRow key={i}>
                                <TableCell>{u.user}</TableCell>
                                <TableCell>{formatDate(parseISODate(u.updatedAt), 'yyyy-MM hh:mm')}</TableCell>
                                <TableCell>{u.verified ? 'Approved' : 'Unapproved'}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableCell>
                    </TableRow>
                  )})}
              </TableBody>
            </Table>
          </Paper>
        )}
        {status === 'idle' && total < 1 && <Typography>No reviews found.</Typography>}
      </Paper>
    </Layout>
  )
}

export default Reviews
