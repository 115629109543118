import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormHelperText from '@material-ui/core/FormHelperText'
import Checkbox from '@material-ui/core/Checkbox'
import axios from 'axios'
import { withSnackbar } from 'notistack'

import AlertDialog from '../AlertDialog'
import { parseError } from '../../lib/helpers'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(0, 3),
  },
}))

const ConfirmDialogContent = ({
  hasFetchAppOrder,
  isFCChecked,
  isFAChecked,
  handleFAChange,
  handleFCChange,
}) => {
  const classes = useStyles()

  return (
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Select emails to resend to the customer:
      </DialogContentText>
      <FormControl required component="fieldset" className={classes.formControl}>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={isFCChecked} onChange={handleFCChange} value="foxycart" />}
            label="Receipt (FoxyCart)"
          />
          {hasFetchAppOrder && (
            <>
              <FormControlLabel
                control={
                  <Checkbox checked={isFAChecked} onChange={handleFAChange} value="fetchapp" />
                }
                label="Download links (FetchApp)"
              />
              <FormHelperText>Resending download links will also reopen the order.</FormHelperText>
            </>
          )}
        </FormGroup>
      </FormControl>
    </DialogContent>
  )
}
ConfirmDialogContent.propTypes = {
  hasFetchAppOrder: PropTypes.bool.isRequired,
  handleFAChange: PropTypes.func.isRequired,
  handleFCChange: PropTypes.func.isRequired,
  isFAChecked: PropTypes.bool,
  isFCChecked: PropTypes.bool,
}
ConfirmDialogContent.defaultProps = {
  isFAChecked: false,
  isFCChecked: false,
}

const ResendEmailsLink = ({
  enqueueSnackbar,
  hasFetchAppOrder,
  isLoading,
  resendEmailsLink,
  transactionId,
  className,
}) => {
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [isSending, setIsSending] = useState(false)

  const [isFAChecked, setFAChecked] = useState(true) // send FetchApp emails
  const [isFCChecked, setFCChecked] = useState(true) // send FoxyCart emails

  const handleFAChange = e => setFAChecked(e.target.checked)
  const handleFCChange = e => setFCChecked(e.target.checked)

  const handleResendEmails = async () => {
    setConfirmDialogOpen(false)
    setIsSending(true)

    try {
      const promises = [
        isFCChecked
          ? axios.get(`/makeFoxyRequest?url=${resendEmailsLink}&method=POST`)
          : new Promise(resolve => resolve('fake')),
        hasFetchAppOrder && isFAChecked
          ? axios.get(`/makeFetchappRequest?url=/orders/${transactionId}/send_email?method=POST`)
          : new Promise(resolve => resolve('fake')),
      ]
      const [fcResponse, faResponse] = await Promise.all(promises)
      fcResponse !== 'fake' && enqueueSnackbar(fcResponse.data.message, { variant: 'success' })
      faResponse !== 'fake' && enqueueSnackbar('Download links email sent.', { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(parseError(e), { variant: 'error' })
    } finally {
      setIsSending(false)
    }
  }

  if (isLoading) return null

  return (
    <div className={className}>
      <Button
        disabled={isSending}
        onClick={() => setConfirmDialogOpen(true)}
        size="small"
        variant="outlined"
      >
        resend order
      </Button>
      <AlertDialog
        dialogContent={
          <ConfirmDialogContent
            hasFetchAppOrder={hasFetchAppOrder}
            handleFAChange={handleFAChange}
            handleFCChange={handleFCChange}
            isFAChecked={isFAChecked}
            isFCChecked={isFCChecked}
          />
        }
        dialogTitle="Resend Order"
        handleClose={() => setConfirmDialogOpen(false)}
        handleOk={handleResendEmails}
        isOpen={isConfirmDialogOpen}
        isOkButtonDisabled={!isFAChecked && !isFCChecked}
        okButtonText="Send emails"
      />
    </div>
  )
}

ResendEmailsLink.propTypes = {
  className: PropTypes.string,
  enqueueSnackbar: PropTypes.func.isRequired, // from notistack
  hasFetchAppOrder: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  resendEmailsLink: PropTypes.string.isRequired,
  transactionId: PropTypes.number.isRequired,
}
ResendEmailsLink.defaultProps = {
  className: null,
  hasFetchAppOrder: false,
}

export default withSnackbar(ResendEmailsLink)
