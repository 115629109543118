import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import MUILink from '@material-ui/core/Link'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Box from '@material-ui/core/Box'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import axios from 'axios'
import { Link } from 'react-router-dom'
import sortBy from 'lodash/sortBy'

import InfoButton from '../InfoButton'
import Title from '../Title'
import { parseError, GBP, getDateRangeForMongo } from '../../lib/helpers'

const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  flexGrow: {
    flexGrow: 1,
  },
}))

const BestSellersWidget = () => {
  const classes = useStyles()
  const [isLoaded, setIsLoaded] = useState(false)
  const [data, setData] = useState([])
  const [error, setError] = useState(false)
  const [dateRange, setDateRange] = useState('week')
  const [sortField, setSortField] = useState('count')

  useEffect(() => {
    let didCancel = false

    const fetchBestSellers = async () => {
      setIsLoaded(false)
      setError(false)

      const [startDate, endDate] = getDateRangeForMongo(dateRange)

      try {
        const response = await axios.get(
          `/fetchBestSellers?startDate=${startDate}&endDate=${endDate}`
        )
        // console.log(response)
        if (didCancel) return
        setData(response.data)
      } catch (e) {
        if (didCancel) return
        setError(parseError(e))
      } finally {
        if (!didCancel) {
          setIsLoaded(true)
        }
      }
    }

    fetchBestSellers()

    return () => {
      didCancel = true
    }
  }, [dateRange])

  const handleDateRangeChange = e => setDateRange(e.target.value)
  const handleSortFieldChange = e => setSortField(e.target.value)

  if (!isLoaded) return <CircularProgress variant="indeterminate" />

  const sortedData = sortBy(data, sortField).reverse()

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
      <Title>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            Best Sellers
            <InfoButton
              tooltipContent={
                <>
                  <Typography gutterBottom>Where does this data come from?</Typography>
                  <Typography variant="body2" gutterBottom>
                    Ginger GM's own database. It's close to being up-to-date, but there might be
                    slight discrepancies with live data.
                  </Typography>
                  <Typography gutterBottom>What do the product codes mean?</Typography>
                  <Typography variant="body2" gutterBottom>
                    They're SKUs. I haven't got round to putting full product descriptions in here
                    just yet.
                  </Typography>
                  <Typography gutterBottom>Are the totals accurate?</Typography>
                  <Typography variant="body2" gutterBottom>
                    Pretty close, see the first answer. The GBP totals don't include discounts or
                    refunds.
                  </Typography>
                </>
              }
            />
          </Box>
          <Select value={dateRange} onChange={handleDateRangeChange}>
            <MenuItem value="day">Today</MenuItem>
            <MenuItem value="week">This week</MenuItem>
            <MenuItem value="month">This month</MenuItem>
            <MenuItem value="year">This year</MenuItem>
            <MenuItem value="all">All time</MenuItem>
          </Select>
        </Box>
      </Title>
      <Box display="flex" justifyContent="center">
        <RadioGroup name="sort-field" value={sortField} onChange={handleSortFieldChange} row>
          <FormControlLabel value="count" control={<Radio />} label="Count" />
          <FormControlLabel value="amountGross" control={<Radio />} label="GBP" />
        </RadioGroup>
      </Box>
      {error && <Typography color="error">{error}</Typography>}
      {!error && sortedData.length === 0 ? (
        <Typography>No sales found for the selected dates.</Typography>
      ) : (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell align="right">
                {sortField === 'count' && 'Count'}
                {sortField === 'amountGross' && 'GBP'}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.slice(0, 5).map(d => {
              return (
                <TableRow key={d.code}>
                  <TableCell>{d.code}</TableCell>
                  {sortField === 'count' && <TableCell align="right">{d.count}</TableCell>}
                  {sortField === 'amountGross' && (
                    <TableCell align="right">{GBP(d.amountGross, { precision: 0 })}</TableCell>
                  )}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      )}
      <div className={classes.flexGrow} />
      <div className={classes.seeMore}>
        <MUILink component={Link} to="/reports/best-sellers">
          See more best sellers
        </MUILink>
      </div>
    </Box>
  )
}

export default BestSellersWidget
