import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'
import Input from '@material-ui/core/Input'
import axios from 'axios'
import { withSnackbar } from 'notistack'

const marks = [
  { value: 0, label: '0%' },
  { value: 10, label: '10%' },
  { value: 20, label: '20%' },
  { value: 30, label: '30%' },
  { value: 40, label: '40%' },
  { value: 50, label: '50%' },
  { value: 60, label: '60%' },
  { value: 70, label: '70%' },
  { value: 80, label: '80%' },
  { value: 90, label: '90%' },
  { value: 100, label: '100%' },
]

const useStyles = makeStyles(theme => ({
  grid: {
    padding: theme.spacing(0, 2),
  },
  slider: {
    width: '100%',
  },
}))

const CouponDiscounts = ({
  couponId,
  coupon_discount_type,
  coupon_discount_details,
  enqueueSnackbar,
}) => {
  const classes = useStyles()
  const [discountType, setDiscountType] = useState(coupon_discount_type.split('_')[1])
  const [discountAmount, setDiscountAmount] = useState(
    parseFloat(coupon_discount_details.split('-')[1])
  )
  const [isSaving, setSaving] = useState(false)
  const isFirstUpdate = useRef(true)

  useEffect(() => {
    let didCancel = false

    const save = async () => {
      try {
        if (discountAmount.toString().trim() === '') return
        setSaving(true)

        const type = `quantity_${discountType}`
        const details = `0-${discountAmount}`

        await axios.get(
          `/makeFoxyRequest?url=https://api.foxycart.com/coupons/${couponId}&method=patch&coupon_discount_details=${details}&coupon_discount_type=${type}`
        )
        // console.log(response.data)
        enqueueSnackbar(`Coupon updated.`, { variant: 'success' })
      } catch (e) {
        console.log(e)
        enqueueSnackbar(`Coupon update failed: ${e}.`, { variant: 'error' })
      } finally {
        if (didCancel) return
        setSaving(false)
      }
    }

    if (isFirstUpdate.current) {
      isFirstUpdate.current = false
    } else {
      save()
    }

    return () => {
      didCancel = true
    }
  }, [couponId, enqueueSnackbar, discountType, discountAmount])

  const handleDiscountTypeChange = e => {
    setDiscountType(e.target.value)
  }

  return (
    <Box mt={2}>
      <Grid container className={classes.grid}>
        <Grid item xs={4}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Discount type</FormLabel>
            <RadioGroup
              aria-label="discount-type"
              row
              name="discount-type"
              value={discountType}
              onChange={handleDiscountTypeChange}
            >
              <FormControlLabel
                value="amount"
                control={<Radio disabled={isSaving} />}
                label="Fixed amount"
              />
              <FormControlLabel
                value="percentage"
                control={<Radio disabled={isSaving} />}
                label="Percentage"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={8}>
          {discountType === 'percentage' ? (
            <>
              <Typography id="discrete-slider" gutterBottom>
                Discount percentage
              </Typography>
              <Slider
                className={classes.slider}
                disabled={isSaving}
                valueLabelDisplay="auto"
                valueLabelFormat={x => `${x}%`}
                onChangeCommitted={(_, value) => {
                  setDiscountAmount(value)
                }}
                aria-labelledby="discrete-slider"
                step={1}
                value={discountAmount || 0}
                min={0}
                max={100}
                marks={marks}
              />
            </>
          ) : (
            <>
              <Typography id="amount">Discount amount</Typography>
              <Input
                aria-labelledby="amount"
                value={discountAmount}
                onChange={e => {
                  setDiscountAmount(e.target.value)
                }}
                startAdornment={<InputAdornment position="start">£</InputAdornment>}
                type="number"
                inputProps={{
                  min: 0,
                  step: 1,
                }}
              />
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

CouponDiscounts.propTypes = {
  couponId: PropTypes.string.isRequired,
  coupon_discount_type: PropTypes.string.isRequired,
  coupon_discount_details: PropTypes.string.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired, // from notistack
}

export default withSnackbar(CouponDiscounts)
