import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import sortBy from 'lodash/sortBy'
import getYear from 'date-fns/getYear'

const today = new Date()
const currentYear = getYear(today)
const options = []
let y = currentYear
// GGM first online sale 2014
while (y >= 2014 && y <= currentYear) {
  options.push({ value: y.toString(), label: y.toString() })
  y--
}

const useStyles = makeStyles(theme => ({
  formControl: {
    // marginRight: theme.spacing(2),
    minWidth: 160,
  },
}))

const YearSelect = ({ value, handleChange, inputLabelText, excludeYears, SelectMenuItem }) => {
  const classes = useStyles()

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="quarter-simple">{inputLabelText}</InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        inputProps={{
          name: 'year',
          id: 'year-simple',
        }}
      >
        {SelectMenuItem}
        {sortBy(
          options.filter(y => !excludeYears.includes(y.value)),
          'value'
        )
          .reverse()
          .map(o => (
            <MenuItem key={o.value} value={o.value}>
              {o.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}

YearSelect.propTypes = {
  excludeYears: PropTypes.arrayOf(PropTypes.string),
  handleChange: PropTypes.func.isRequired,
  inputLabelText: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  SelectMenuItem: PropTypes.node,
}
YearSelect.defaultProps = {
  excludeYears: [],
  inputLabelText: 'Select year',
  SelectMenuItem: null,
}

export default YearSelect
