import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles(theme => ({
  textField: {
    flexBasis: 200,
  },
}))

const AddCouponCode = ({ addCouponCode }) => {
  const classes = useStyles()
  const [value, setValue] = useState('')
  const [isDisabled, setDisabled] = useState(false)

  const handleAdd = async () => {
    if (value.trim() === '') return
    setDisabled(true)
    await addCouponCode(value)
    // we don't need to reset state here as we reload the entire coupon code list
    // setValue('')
    // setDisabled(false)
  }

  return (
    <Box display="flex" mt={1} mb={3} justifyContent="center" width="100%">
      <FormControl
        className={classes.textField}
        onKeyDown={e => {
          if (e.key === 'Escape') {
            e.preventDefault()
            setValue('')
          }
          if (e.key === 'Enter') {
            e.preventDefault()
            handleAdd()
          }
        }}
      >
        <Input
          id="adornment-add"
          disabled={isDisabled}
          value={value}
          onChange={e => setValue(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="add coupon code" onClick={handleAdd}>
                <AddCircleOutlineIcon />
              </IconButton>
            </InputAdornment>
          }
          placeholder="Add coupon code"
        />
      </FormControl>
    </Box>
  )
}

AddCouponCode.propTypes = {
  addCouponCode: PropTypes.func.isRequired,
}

export default AddCouponCode
