import startOfToday from 'date-fns/startOfToday'
import endOfToday from 'date-fns/endOfToday'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import startOfYear from 'date-fns/startOfYear'
import endOfYear from 'date-fns/endOfYear'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import format from 'date-fns/format'
import currency from 'currency.js'
// import axios from 'axios'

export const orderEmailsToExclude = [
  'siansell@gmail.com',
  'simona@gingergm.com',
  'siansell@protonmail.com',
  'simon@gingergm.com',
  'simon@ansellcomputing.com',
  'kayleygrub@gmail.com',
]

export const isNumeric = n => {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

export const GBP = (value, opts = {}) =>
  currency(value, {
    symbol: opts.symbol || '£',
    precision: opts.precision || 2,
    formatWithSymbol: opts.formatWithSymbol === false ? false : true,
  }).format()

export const parseError = e => {
  if (e.response) {
    return `${e.response.status} ${e.response.statusText}: ${e.response.data}`
  }
  return e.toString()
}

// Returns strings [startDate, endData] to pass to mongo db query
export const getDateRangeForMongo = dateRange => {
  const dateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
  const now = new Date()

  switch (dateRange) {
    case 'week':
      return [
        format(startOfWeek(now, { weekStartsOn: 1 }), dateFormat),
        format(endOfWeek(now, { weekStartsOn: 1 }), dateFormat),
      ]
    case 'month':
      return [format(startOfMonth(now), dateFormat), format(endOfMonth(now), dateFormat)]
    case 'year':
      return [format(startOfYear(now), dateFormat), format(endOfYear(now), dateFormat)]
    case 'all':
      return [null, null]
    default:
      return [format(startOfToday(), dateFormat), format(endOfToday(), dateFormat)]
  }
}

export const getItemCategoryText = code => {
  switch (code.trim().toLowerCase()) {
    case 'book':
    case 'gingergm-book':
      return 'BK'
    case 'bundle':
      return 'BND'
    case 'chessbase-dvd':
    case 'gingergm-dvd':
    case 'dvd':
      return 'DVD'
    case 'dvd-download':
    case 'gingergm-dvd-download':
    case 'gingergm-specials-dvd-download':
      return 'DLD'
    case 'ichess-dvd-download':
      return 'ICS'
    case 'ebook':
    case 'gingergm-ebook':
      return 'EBK'
    case 'gingergm-pgn':
    case 'pgn':
      return 'PGN'
    default:
      return code
  }
}

// Parse prismic IO data. Returns full author name from slug (uid).
// param authors is the array of prismic documents of type author.
export const getAuthorFullName = (authors, authorSlug) => {
  const authorObj = authors.find(a => a.uid === authorSlug)
  return authorObj
    ? `${authorObj.data.chess_title ? authorObj.data.chess_title + ' ' : ''}${authorObj.data.name}`
    : authorSlug
}
