import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import getQuarter from 'date-fns/getQuarter'
import getYear from 'date-fns/getYear'
import axios from 'axios'
import sortBy from 'lodash/sortBy'
import CircularProgress from '@material-ui/core/CircularProgress'
import { CSVLink } from 'react-csv'
import MUILink from '@material-ui/core/Link'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import QuarterSelect from '../QuarterSelect'
import Layout from '../../Layout'
import { parseError, GBP } from '../../../lib/helpers'

const today = new Date()
const currentQuarter = getQuarter(today)
const currentYear = getYear(today)
const initialQuarter = `${currentYear.toString()}-${currentQuarter}`

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  tabs: {
    marginBottom: theme.spacing(2),
  },
  headerTable: {
    margin: theme.spacing(4, 0),
  },
  actions: {
    marginBottom: theme.spacing(4),
  },
}))

const VATReport = () => {
  const classes = useStyles()
  const [selectedQuarter, setQuarter] = useState(initialQuarter)
  const [error, setError] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [reportData, setReportData] = useState([])

  useEffect(() => {
    let didCancel = false

    const fetchReport = async () => {
      setError(false)
      setIsLoaded(false)
      setReportData([])

      try {
        const response = await axios.get(`/fetchVATReport?reportQuarter=${selectedQuarter}`)
        // console.log(response.data)
        if (didCancel) return
        setReportData(sortBy(response.data, 'transaction_date').reverse())
      } catch (e) {
        console.log(e)
        if (didCancel) return
        setError(parseError(e))
      } finally {
        if (!didCancel) {
          setIsLoaded(true)
        }
      }
    }

    fetchReport()

    return () => {
      didCancel = true
    }
  }, [selectedQuarter])

  const csvData = reportData.map(t => {
    return {
      transaction_id: t.transaction_id,
      foxycart_customer_id: t.foxycart_customer_id,
      transaction_date: t.transaction_date,
      product_total: t.product_total,
      tax_total: t.tax_total,
      shipping_total: t.shipping_total,
      discount_total: t.discount_total,
      total_order: t.total_order,
      customer_country: t.customer_country,
    }
  })

  const [year, month] = selectedQuarter.split('-')

  return (
    <Layout title="VAT Report">
      <Paper className={classes.paper}>
        <Paper className={clsx(classes.paper, classes.tabPanelPaper)} elevation={2}>
          <Box display="flex" justifyContent="center" mb={4}>
            <QuarterSelect value={selectedQuarter} handleChange={e => setQuarter(e.target.value)} />
          </Box>
        </Paper>
        <Paper className={clsx(classes.paper, classes.tabPanelPaper)} elevation={2}>
          {!isLoaded && <CircularProgress />}
          {error && <Typography color="error">{error}</Typography>}
          {!error && isLoaded && (
            <>
              <Box display="flex" justifyContent="center" width="100%" className={classes.actions}>
                <MUILink
                  component={CSVLink}
                  data={csvData}
                  filename={`ginger-gm-vat-${selectedQuarter}.csv`}
                  className={classes.actionLink}
                  variant="body1"
                >
                  Download CSV file
                </MUILink>
              </Box>
              <Typography variant="h5" component="h2">
                Ginger GM VAT Report: Quarter {month.padStart(2, '0')} {year}
              </Typography>
              <Table className={classes.headerTable}>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h6" component="h2">
                        Number of transactions:
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="h6" component="h2">
                        {reportData.length}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h6" component="h2">
                        Total sales (including VAT):
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="h6" component="h2">
                        {GBP(reportData.reduce((sum, t) => sum + t.total_order, 0))}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h6" component="h2">
                        VAT received:
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="h6" component="h2">
                        {GBP(reportData.reduce((sum, t) => sum + t.tax_total, 0))}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h6" component="h2">
                        Net sales (Total sales - VAT received):
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="h6" component="h2">
                        {GBP(
                          reportData.reduce((sum, t) => sum + t.total_order, 0) -
                            reportData.reduce((sum, t) => sum + t.tax_total, 0)
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </>
          )}
        </Paper>
      </Paper>
    </Layout>
  )
}
export default VATReport
