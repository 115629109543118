import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'

import DeleteButton from './DeleteButton'
import EditButton from './EditButton'
import { GBP, getAuthorFullName } from '../../../../../lib/helpers'

const useStyles = makeStyles(theme => {
  // console.log(theme)
  return {
    button: {
      // marginRight: theme.spacing(1),
    },
    caption: {
      fontSize: theme.typography.caption.fontSize,
    },
    disabled: {
      color: 'rgba(0, 0, 0, 0.26)',
    },
  }
})

const CommissionRow = ({
  authors,
  commission: c,
  products,
  setCommissionPercentages,
  year,
  setEditId,
  editId,
}) => {
  const classes = useStyles()
  const authorName = getAuthorFullName(authors, c.authorSlug)

  const productObj = products.find(p => p.uid === c.purchaseOption)
  const productText = productObj ? productObj.data.product.data.title[0].text : c.purchaseOption
  const productDescriptionText = productObj ? productObj.data.description : null

  const isDisabled = editId !== null && editId !== c._id

  return (
    <TableRow>
      <TableCell>
        <Box display="flex">
          <EditButton id={c._id} onClick={setEditId} isDisabled={isDisabled} />
          <DeleteButton
            commission={c}
            isDisabled={isDisabled}
            setCommissionPercentages={setCommissionPercentages}
            year={year}
          />
        </Box>
      </TableCell>
      <TableCell className={clsx({ [classes.disabled]: isDisabled })}>
        {c.isActive ? 'Active' : 'Not active'}
      </TableCell>
      <TableCell className={clsx({ [classes.disabled]: isDisabled })}>{authorName}</TableCell>
      <TableCell className={clsx({ [classes.disabled]: isDisabled })}>
        {productObj && <span>{productText}</span>}
        {productDescriptionText && (
          <>
            <br />
            <span className={classes.caption}>{productDescriptionText}</span>
          </>
        )}
        {productObj && <br />}
        <span className={productObj ? classes.caption : null}>{c.purchaseOption}</span>
      </TableCell>
      <TableCell className={clsx({ [classes.disabled]: isDisabled })}>
        {c.commissionPercentage ? 'Percentage' : 'Fixed amount'}
      </TableCell>
      <TableCell align="right" className={clsx({ [classes.disabled]: isDisabled })}>
        {c.commissionPercentage ? `${c.commissionPercentage}%` : `${GBP(c.commissionFixedGBP)}`}
      </TableCell>
    </TableRow>
  )
}

CommissionRow.propTypes = {
  authors: PropTypes.arrayOf(PropTypes.object).isRequired,
  commission: PropTypes.object.isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  setCommissionPercentages: PropTypes.func.isRequired,
  setEditId: PropTypes.func.isRequired,
  year: PropTypes.string.isRequired,
  editId: PropTypes.string,
}
CommissionRow.defaultProps = {
  editId: null,
}

export default CommissionRow
