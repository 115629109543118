import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import axios from 'axios'
import { withSnackbar } from 'notistack'

import { parseError } from '../../../../lib/helpers'
import AddProductDialog from './AddProductDialog'

const useStyles = makeStyles(theme => ({
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
}))

const AddProductCodeButton = ({ handleClick }) => {
  const classes = useStyles()

  return (
    <Button variant="outlined" size="small" onClick={handleClick}>
      Add product code
      <AddIcon className={classes.rightIcon} />
    </Button>
  )
}

AddProductCodeButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
}

const ProductsSection = ({ purchaseOptions, products, coupon, enqueueSnackbar }) => {
  const [isAddDialogOpen, setAddDialogOpen] = useState(false)
  const [localPurchaseOptions, setLocalPurchaseOptions] = useState(purchaseOptions)

  const handleAddProduct = async purchaseOption => {
    try {
      setAddDialogOpen(false)
      if (localPurchaseOptions.includes(purchaseOption)) {
        enqueueSnackbar(`Purchase option ${purchaseOption} already exists.`)
        return
      }
      const newPurchaseOptions = `${localPurchaseOptions.join(',')},${purchaseOption}`
      await axios.get(
        `/makeFoxyRequest?url=${coupon._links.self.href}&method=patch&product_code_restrictions=${newPurchaseOptions}`
      )
      setLocalPurchaseOptions(newPurchaseOptions.split(','))
      enqueueSnackbar(`Add purchase option ${purchaseOption} succeeded.`, { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(`Add purchase option ${purchaseOption} failed: ${parseError(e)}`, {
        variant: 'error',
      })
    }
  }

  const handleUpdateProductCodeRestrictions = async () => {
    try {
      setAddDialogOpen(false)
      const productCodeRestrictions = localPurchaseOptions.join(',')
      await axios.get(
        `/makeFoxyRequest?url=${coupon._links.self.href}&method=patch&product_code_restrictions=${productCodeRestrictions}`
      )
      setLocalPurchaseOptions(productCodeRestrictions.split(','))
      enqueueSnackbar(`Update product code restrictions succeeded.`, { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(`Update product code restrictions failed: ${parseError(e)}`, {
        variant: 'error',
      })
    }
  }

  const handleDeletePurchaseOption = async purchaseOption => {
    try {
      const newPurchaseOptions = localPurchaseOptions.filter(p => p !== purchaseOption)
      const response = await axios.get(
        `/makeFoxyRequest?url=${coupon._links.self.href}&method=patch&product_code_restrictions=${newPurchaseOptions}`
      )
      // console.log(response.data.product_code_restrictions)
      setLocalPurchaseOptions(
        response.data.product_code_restrictions.split(',').filter(p => p !== '')
      )
      // setLocalPurchaseOptions(newPurchaseOptions.filter(p => p !== ''))
      enqueueSnackbar(`Delete purchase option ${purchaseOption} succeeded.`, { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(`Delete purchase option ${purchaseOption} failed: ${parseError(e)}`, {
        variant: 'error',
      })
    }
  }

  return (
    <>
      <Box mb={2}>
        <TextField
          label="Product code restrictions"
          fullWidth
          multiline
          onChange={e => setLocalPurchaseOptions(e.target.value.split(','))}
          rows={5}
          value={localPurchaseOptions.filter(x => x !== '').join(',')}
          variant="outlined"
          helperText="ADVANCED. Product code restrictions string (see FoxyCart docs). Use this to directly set the string."
        />
        <Button variant="contained" onClick={handleUpdateProductCodeRestrictions}>
          Save
        </Button>
      </Box>
      {localPurchaseOptions.length === 0 ? (
        <Typography gutterBottom>
          This coupon is valid for all products. Select specific products with the button below.
        </Typography>
      ) : (
        <>
          <Typography gutterBottom>
            This coupon is valid for the following product codes only:
          </Typography>
          <Box mt={2}>
            <Table size="small">
              <TableBody>
                {localPurchaseOptions.map(c => {
                  const product = products.find(p => p.uid === c)
                  if (product) {
                    return (
                      <TableRow key={product.uid}>
                        <TableCell>{product.data.product.data.title[0].text}</TableCell>
                        <TableCell>{product.data.description}</TableCell>
                        <TableCell align="right">
                          <IconButton onClick={() => handleDeletePurchaseOption(product.uid)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  }

                  return (
                    <TableRow key={c}>
                      <TableCell>{c}</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Box>
        </>
      )}
      <Box mt={2}>
        <AddProductCodeButton handleClick={() => setAddDialogOpen(true)} />
      </Box>
      <AddProductDialog
        isOpen={isAddDialogOpen}
        handleClose={() => setAddDialogOpen(false)}
        handleAdd={handleAddProduct}
        products={products}
      />
    </>
  )
}

ProductsSection.propTypes = {
  coupon: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired, // from notistack
  purchaseOptions: PropTypes.arrayOf(PropTypes.string),
  products: PropTypes.arrayOf(PropTypes.object),
}
ProductsSection.defaultProps = {
  purchaseOptions: [],
  products: [],
}

export default withSnackbar(ProductsSection)
