import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import axios from 'axios'
import { withSnackbar } from 'notistack'
import CircularProgress from '@material-ui/core/CircularProgress'

import { parseError } from '../../../../lib/helpers'
import AddCategoryDialog from './AddCategoryDialog'

const useStyles = makeStyles(theme => ({
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
}))

const AddCategoryButton = ({ handleClick, isDisabled }) => {
  const classes = useStyles()

  return (
    <Button variant="outlined" size="small" onClick={handleClick} disabled={isDisabled}>
      Add category
      <AddIcon className={classes.rightIcon} />
    </Button>
  )
}

AddCategoryButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
}

const Categories = ({ coupon, enqueueSnackbar }) => {
  const [isAddDialogOpen, setAddDialogOpen] = useState(false)
  const [allCategories, setAllCategories] = useState([])
  const [error, setError] = useState(false)
  const [localCategories, setLocalCategories] = useState(
    (coupon._embedded && coupon._embedded['fx:coupon_item_categories']) || []
  )
  const [isRequestInProgress, setRequestInProgress] = useState(false)

  useEffect(() => {
    let didCancel = false

    const fetchCategories = async () => {
      setRequestInProgress(true)
      try {
        const response = await axios.get(
          `/makeFoxyRequest?url=${coupon._links['fx:store'].href}/item_categories&method=get`
        )
        if (didCancel) return
        setAllCategories(response.data._embedded['fx:item_categories'])
      } catch (e) {
        if (didCancel) return
        setError(parseError(e))
      } finally {
        setRequestInProgress(false)
      }
    }

    fetchCategories()

    return () => {
      didCancel = true
    }
  }, [coupon._links])

  const handleAddCategory = async category => {
    setAddDialogOpen(false)
    if (
      localCategories
        .map(c => c.item_category_uri || c._links['fx:item_category'].href)
        .includes(category._links.self.href)
    ) {
      enqueueSnackbar(`Category ${category.name} already exists.`)
      return
    }

    try {
      setRequestInProgress(true)
      const response = await axios.get(
        `/makeFoxyRequest?url=${coupon._links['fx:coupon_item_categories'].href}&method=post&item_category_uri=${category._links.self.href}&coupon_uri=${coupon._links.self.href}`
      )
      enqueueSnackbar(response.data.message, { variant: 'success' })
      setLocalCategories([...localCategories, response.data])
    } catch (e) {
      enqueueSnackbar(parseError(e), { variant: 'error' })
    } finally {
      setRequestInProgress(false)
    }
  }

  const handleDeleteCategory = async couponItemCategory => {
    try {
      setRequestInProgress(true)
      const response = await axios.get(
        `/makeFoxyRequest?url=${couponItemCategory._links.self.href}&method=delete`
      )
      enqueueSnackbar(response.data.message, { variant: 'success' })
      // console.log(localCategories, couponItemCategory)
      setLocalCategories(
        localCategories.filter(
          c =>
            c._links['fx:item_category'].href !== couponItemCategory._links['fx:item_category'].href
        )
      )
    } catch (e) {
      enqueueSnackbar(parseError(e), { variant: 'error' })
    } finally {
      setRequestInProgress(false)
    }
  }

  if (allCategories.length === 0) return <CircularProgress />
  // console.log(allCategories)

  return (
    <>
      {error && (
        <Typography gutterBottom color="error">
          {error}
        </Typography>
      )}
      {localCategories.length === 0 ? (
        <Typography gutterBottom>
          This coupon is valid for all categories. Select specific categories with the button below.
        </Typography>
      ) : (
        <>
          <Typography gutterBottom>
            This coupon is valid for the following categories only:
          </Typography>
          <Box mt={2}>
            <Table size="small">
              <TableBody>
                {localCategories.map(c => {
                  // console.log(c)
                  const category = allCategories.find(
                    cat => cat._links.self.href === c._links['fx:item_category'].href
                  )
                  return (
                    <TableRow key={c._links.self.href}>
                      <TableCell>{category ? category.name : c._links.self.href}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={() => handleDeleteCategory(c)}
                          disabled={isRequestInProgress}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Box>
        </>
      )}
      <Box mt={2}>
        <AddCategoryButton
          handleClick={() => setAddDialogOpen(true)}
          isDisabled={isRequestInProgress}
        />
      </Box>
      <AddCategoryDialog
        categories={allCategories}
        isOpen={isAddDialogOpen}
        handleClose={() => setAddDialogOpen(false)}
        handleAdd={handleAddCategory}
      />
    </>
  )
}

Categories.propTypes = {
  coupon: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired, // from notistack
}

export default withSnackbar(Categories)
