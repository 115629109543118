import React from 'react'
import Typography from '@material-ui/core/Typography'

import Layout from './Layout'

const NoMatch = () => {
  return (
    <Layout title="404">
      <Typography>404: Page not found</Typography>
    </Layout>
  )
}
export default NoMatch
