import format from 'date-fns/format'

export const getReportTitle = (
  selectedMonth,
  prefix = 'Sales of iChess products on gingergm.com '
) => `${prefix}${format(
  new Date(
    `${selectedMonth.split('-')[1]}-${(
      parseInt(selectedMonth.split('-')[0], 10) + 1
    ).toString()}-01`
  ),
  'MMMM'
)} ${selectedMonth.split('-')[1]}
`
