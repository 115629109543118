import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import MUILink from '@material-ui/core/Link'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import { CSVLink } from 'react-csv'
import { PDFViewer } from '@react-pdf/renderer'

import PDFReport from './PDFReport'
import { GBP, getAuthorFullName } from '../../../../lib/helpers'

const useStyles = makeStyles(theme => ({
  actionLink: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

const sumCommissionItems = (data, commissions) => {
  return data.reduce((sum, item) => {
    const commission = commissions.find(c => c.purchaseOption === item.code)
    if (!commission) return sum

    const discountTotal = item.discounts ? item.discounts.reduce((sum, d) => sum + d.amount, 0) : 0
    const discountedPrice = item.price * item.quantity + discountTotal
    const discountPercentage =
      item.price * item.quantity > 0 ? discountedPrice / (item.price * item.quantity) : 0

    if (commission.commissionFixedGBP) {
      return sum + commission.commissionFixedGBP * item.quantity * discountPercentage
    }
    if (commission.commissionPercentage) {
      return sum + (commission.commissionPercentage * discountedPrice) / 100
    }

    return 0
  }, 0)
}

const ReportHeader = ({
  authors,
  authorSlug,
  isLoaded,
  error,
  reportData,
  authorCommissions,
  year,
  month,
}) => {
  // console.log(reportData)
  // console.log(authorCommissions)

  const classes = useStyles()

  // const totalCommissions = sumCommissionItems(reportData, authorCommissions)

  const byProduct = groupBy(reportData, 'code')
  const productTotals = sortBy(
    Object.keys(byProduct).map(p => {
      const commissionDetailsObj = authorCommissions.find(c => c.purchaseOption === p)
      let commissionDetails = null
      if (commissionDetailsObj) {
        if (commissionDetailsObj.commissionFixedGBP) {
          commissionDetails = `Fixed ${GBP(commissionDetailsObj.commissionFixedGBP)}`
        } else if (commissionDetailsObj.commissionPercentage) {
          commissionDetails = `${commissionDetailsObj.commissionPercentage}%`
        }
      }

      return {
        code: p,
        count: byProduct[p].reduce((sum, t) => sum + t.quantity, 0),
        commissionDetails,
        name: byProduct[p][0].name,
        selection: byProduct[p][0].selection,
        total: sumCommissionItems(byProduct[p], authorCommissions),
      }
    }),
    'total'
  )
    .filter(p => p.total > 0)
    .reverse()
  // console.log(productTotals)

  const csvData = reportData.map(item => {
    const commission = authorCommissions.find(c => c.purchaseOption === item.code)
    if (!commission) {
      // this case is usually an error in cached report data, due to incorrect commissions
      // being set
      return {}
    }

    const commission_type = commission.commissionFixedGBP ? 'fixed' : 'percentage'
    const commission_amount = commission.commissionFixedGBP || commission.commissionPercentage

    const discountTotal = item.discounts ? item.discounts.reduce((sum, d) => sum + d.amount, 0) : 0
    const discountedPrice = item.price * item.quantity + discountTotal
    const discountPercentage =
      item.price * item.quantity > 0 ? discountedPrice / (item.price * item.quantity) : 0

    let commission_value = 0
    if (commission.commissionFixedGBP) {
      commission_value = commission.commissionFixedGBP * item.quantity * discountPercentage
    }
    if (commission.commissionPercentage) {
      commission_value = (commission.commissionPercentage * discountedPrice) / 100
    }

    return {
      transaction_id: item.id,
      transaction_date: item.transaction_date,
      product: item.name,
      selection: item.selection,
      sku: item.code,
      price_gbp: GBP(item.price, { formatWithSymbol: false }),
      quantity: item.quantity,
      coupons: item.discounts.map(d => d.coupon).join(', '),
      total_discount_gbp: GBP(discountTotal, { formatWithSymbol: false }),
      sale_price_gbp: GBP(item.price * item.quantity + discountTotal, {
        formatWithSymbol: false,
      }),
      commission_type,
      commission_amount,
      commission_value,
    }
  })

  const authorName = getAuthorFullName(authors, authorSlug)

  if (!isLoaded) return <CircularProgress />
  if (error) return <Typography color="error">{error}</Typography>

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="100%">
      <MUILink
        component={CSVLink}
        data={csvData}
        filename={`${authorSlug}-commissions-${year}.csv`}
        className={classes.actionLink}
        variant="body1"
      >
        Download CSV file
      </MUILink>
      <PDFViewer height={800} width="100%">
        <PDFReport reportData={productTotals} year={year} month={month} author={authorName} />
      </PDFViewer>
    </Box>
  )
}

ReportHeader.propTypes = {
  authors: PropTypes.arrayOf(PropTypes.object).isRequired,
  authorCommissions: PropTypes.arrayOf(PropTypes.object).isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  isLoaded: PropTypes.bool.isRequired,
  reportData: PropTypes.arrayOf(PropTypes.object).isRequired,
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  authorSlug: PropTypes.string.isRequired,
}

export default ReportHeader
