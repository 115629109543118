import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import netlifyIdentity from 'netlify-identity-widget'

const useStyles = makeStyles(theme => ({
  button: {
    textTransform: 'none',
  },
  typography: {
    fontFamily: theme.typography.fontFamily,
  },
  rightIcon: {
    marginLeft: theme.spacing(),
    position: 'relative',
    top: 4,
  },
}))

const UserMenu = ({ full_name }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        aria-haspopup="true"
        aria-owns={anchorEl ? 'user-menu' : undefined}
        className={classes.button}
        color="inherit"
        onClick={handleClick}
      >
        <Typography variant="h6" color="inherit" className={classes.typography}>
          {full_name}
          <ArrowDropDownIcon className={classes.rightIcon} />
        </Typography>
      </Button>
      <Menu anchorEl={anchorEl} id="user-menu" onClose={handleClose} open={Boolean(anchorEl)}>
        <MenuItem onClick={() => netlifyIdentity.logout()}>Logout</MenuItem>
      </Menu>
    </>
  )
}

UserMenu.propTypes = {
  full_name: PropTypes.string.isRequired,
}

export default UserMenu
