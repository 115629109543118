import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { withSnackbar } from 'notistack'
import Typography from '@material-ui/core/Typography'

import EditableText from '../../../EditableText'

const CouponName = ({ couponId, name, enqueueSnackbar }) => {
  return (
    <EditableText
      ContainerEl={<Typography variant="h5" component="h2" />}
      initialValue={name.replace('GGM::', '')}
      onSave={async value => {
        await axios.get(
          `/makeFoxyRequest?url=https://api.foxycart.com/coupons/${couponId}&method=patch&name=GGM::${encodeURIComponent(
            value
          )}`
        )
      }}
      snackbarMessages={{
        success: 'Coupon name updated.',
        error: e => `Coupon name update failed: ${e}.`,
      }}
    />
  )
}

CouponName.propTypes = {
  couponId: PropTypes.string.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
}

export default withSnackbar(CouponName)
