import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import { LineChart, Line, XAxis, YAxis, Legend, ResponsiveContainer, Tooltip } from 'recharts'
import Box from '@material-ui/core/Box'
import { red, green, blue } from '@material-ui/core/colors'
import CircularProgress from '@material-ui/core/CircularProgress'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'

import Title from '../Title'
import { GBP, parseError, getDateRangeForMongo } from '../../lib/helpers'

const createData = (index, orderTotal, profitTotal, itemTotal, taxTotal, shippingTotal) => {
  return {
    index,
    orderTotal,
    profitTotal,
    itemTotal,
    taxTotal,
    shippingTotal,
  }
}

const useStyles = makeStyles(() => ({
  flexGrow: {
    flexGrow: 1,
  },
}))

const SalesWidget = () => {
  const classes = useStyles()
  const [isLoaded, setIsLoaded] = useState(false)
  const [data, setData] = useState([])
  const [error, setError] = useState(false)
  const [countTransactions, setCountTransactions] = useState(null)
  const [dateRange, setDateRange] = useState('week')

  useEffect(() => {
    let didCancel = false

    const fetchSalesData = async () => {
      setIsLoaded(false)
      setError(false)

      try {
        const [startDate, endDate] = getDateRangeForMongo(dateRange)

        const response = await axios.get(
          `/fetchSalesData?startDate=${startDate}&endDate=${endDate}`
        )
        // console.log(response.data)
        setData(
          response.data.map((t, index) => {
            const orderTotal = response.data
              .slice(0, index + 1)
              .reduce((sum, x) => sum + x.total_order, 0)
            const itemTotal = response.data
              .slice(0, index + 1)
              .reduce((sum, x) => sum + x.total_item_price, 0)
            const taxTotal = response.data
              .slice(0, index + 1)
              .reduce((sum, x) => sum + x.total_tax, 0)
            const shippingTotal = response.data
              .slice(0, index + 1)
              .reduce((sum, x) => sum + x.total_shipping, 0)
            const profitTotal = orderTotal - taxTotal - shippingTotal
            return createData(index, orderTotal, profitTotal, itemTotal, taxTotal, shippingTotal)
          })
        )
        setCountTransactions(response.data.length)
      } catch (e) {
        if (didCancel) return
        setError(parseError(e))
      } finally {
        if (!didCancel) {
          setIsLoaded(true)
        }
      }
    }

    fetchSalesData()

    return () => {
      didCancel = true
    }
  }, [dateRange])

  const handleDateRangeChange = e => setDateRange(e.target.value)

  if (!isLoaded) return <CircularProgress variant="indeterminate" />

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
      <Title>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {countTransactions === 0 ? 'No' : countTransactions} sale
          {countTransactions !== 1 && 's'}
          {countTransactions === 0 && ' yet'}
          {countTransactions !== 0 && ', '}
          {data.length > 0 && GBP(data[data.length - 1].orderTotal)}
          <Select value={dateRange} onChange={handleDateRangeChange}>
            <MenuItem value="day">Today</MenuItem>
            <MenuItem value="week">This week</MenuItem>
            <MenuItem value="month">This month</MenuItem>
            <MenuItem value="year">This year</MenuItem>
            <MenuItem value="all">All time</MenuItem>
          </Select>
        </Box>
      </Title>
      <div className={classes.flexGrow} />
      {error && <Typography color="error">{error}</Typography>}
      {!error && data.length > 0 && (
        <ResponsiveContainer>
          <LineChart
            data={data}
            margin={{
              top: 16,
              right: 16,
              bottom: 0,
              left: 24,
            }}
          >
            <XAxis tick={false} />
            <YAxis tickFormatter={t => GBP(t, { precision: 0 })} />
            <Legend verticalAlign="top" height={50} />
            <Tooltip formatter={t => GBP(t, { precision: 0 })} labelFormatter={() => null} />
            {/* <Line
              name="Items"
              type="monotone"
              dataKey="itemTotal"
              stroke={grey[300]}
              strokeDasharray="3 4 5 2"
              dot={false}
            /> */}
            <Line
              name="Profit"
              type="monotone"
              dataKey="profitTotal"
              stroke={green[900]}
              strokeWidth={2}
              dot={false}
            />
            <Line
              name="Order"
              type="monotone"
              dataKey="orderTotal"
              stroke={green[300]}
              strokeDasharray="3 4 5 2"
              dot={false}
            />
            <Line
              name="Tax"
              type="monotone"
              dataKey="taxTotal"
              stroke={red[300]}
              strokeDasharray="3 4 5 2"
              dot={false}
            />
            <Line
              name="Shipping"
              type="monotone"
              dataKey="shippingTotal"
              stroke={blue[300]}
              strokeDasharray="3 4 5 2"
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </Box>
  )
}

export default SalesWidget
