import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Paper from '@material-ui/core/Paper'
import axios from 'axios'
import sortBy from 'lodash/sortBy'
import Typography from '@material-ui/core/Typography'

import Pagination from '../../../Pagination'
import AddCouponCode from './AddCouponCode'
import NumberOfUsesPerCode from './NumberOfUsesPerCode'
import CodeRow from './CodeRow'

const useStyles = makeStyles(theme => ({
  codeCell: {
    width: 320,
  },
  actionCell: {
    width: 80,
  },
  paper: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
}))

const Codes = ({ coupon, deleteCouponCode, addCouponCode }) => {
  const classes = useStyles()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)

  const totalPages =
    coupon._embedded && coupon._embedded['fx:coupon_codes']
      ? Math.ceil(parseInt(coupon._embedded['fx:coupon_codes'].length, 10) / perPage)
      : 0

  const sortedData =
    coupon._embedded && coupon._embedded['fx:coupon_codes']
      ? sortBy(coupon._embedded['fx:coupon_codes'], c => c.date_modified || '1900-01-01').reverse()
      : []
  const visibleData = sortedData.slice((page - 1) * perPage, (page - 1) * perPage + perPage)

  return (
    <>
      <Paper elevation={3} className={classes.paper}>
        <NumberOfUsesPerCode
          nPerCode={coupon.number_of_uses_allowed_per_code}
          onUpdate={async value => {
            await axios.get(
              `/makeFoxyRequest?url=${coupon._links.self.href}&method=patch&number_of_uses_allowed_per_code=${value}`
            )
          }}
        />
      </Paper>
      <Paper elevation={3} className={classes.paper}>
        <AddCouponCode addCouponCode={addCouponCode} />
        {totalPages > 0 ? (
          <>
            <Pagination
              page={page}
              perPage={perPage}
              perPageMenuItems={[5, 10, 25, 50]}
              setPerPage={setPerPage}
              setPage={setPage}
              totalPages={totalPages}
            />
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.codeCell}>Code</TableCell>
                  <TableCell align="right">Uses to date</TableCell>
                  <TableCell align="right" className={classes.actionCell}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleData.map(couponCode => {
                  return (
                    <CodeRow
                      key={couponCode.code}
                      couponCode={couponCode}
                      deleteCouponCode={deleteCouponCode}
                    />
                  )
                })}
              </TableBody>
            </Table>
          </>
        ) : (
          <Typography>There are no codes for this coupon.</Typography>
        )}
      </Paper>
    </>
  )
}

Codes.propTypes = {
  coupon: PropTypes.object.isRequired,
  addCouponCode: PropTypes.func.isRequired,
  deleteCouponCode: PropTypes.func.isRequired,
}

export default Codes
