import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import clsx from 'clsx'
import axios from 'axios'
import uniq from 'lodash/uniq'

import ReportHeader from './ReportHeader'
import AuthorSelect from '../AuthorSelect'
import { parseError } from '../../../../lib/helpers'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  tabPanelPaper: {
    margin: theme.spacing(2),
  },
  select: {
    marginBottom: theme.spacing(2),
  },
}))

const ReportTab = ({
  authorSlug,
  setAuthorSlug,
  authors,
  commissionPercentages,
  selectedYear,
  selectedMonth,
}) => {
  const classes = useStyles()
  const [error, setError] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [reportData, setReportData] = useState([])

  useEffect(() => {
    if (authorSlug === 'select') {
      setIsLoaded(false)
      setReportData([])
      setError(false)
      return
    }

    let didCancel = false

    const fetchReport = async () => {
      setIsLoaded(false)
      setReportData([])
      setError(false)
      try {
        const response = await axios.get(
          `/fetchCommissionsReport?reportYear=${selectedYear}&authorSlug=${authorSlug}&reportMonth=${selectedMonth}`
        )
        // console.log(response.data)

        // calc the discounts for each item
        const itemsWithDiscounts = []
        /* eslint-disable no-unused-vars */
        for (let item of response.data) {
          // console.log(item)
          /* eslint-enable no-unused-vars */
          if (!item.discounts) {
            itemsWithDiscounts.push({
              ...item,
              discounts: [],
            })
          } else {
            const discountResponse = await axios.get(`/calcItemDiscount?transactionId=${item.id}`)
            // console.log(discountResponse.data)
            const itemDiscounts = discountResponse.data.find(d => d.itemCode === item.code)
            itemsWithDiscounts.push({
              ...item,
              discounts: itemDiscounts.discounts,
            })
          }
        }

        if (didCancel) return
        setReportData(itemsWithDiscounts)
      } catch (e) {
        if (didCancel) return
        setError(parseError(e))
      } finally {
        if (!didCancel) {
          setIsLoaded(true)
        }
      }
    }

    fetchReport()

    return () => {
      didCancel = true
    }
  }, [selectedYear, authorSlug, selectedMonth])

  const authorsWithCommissions = uniq(commissionPercentages.map(c => c.authorSlug))
  const filteredAuthors = authors.filter(a => authorsWithCommissions.includes(a.uid))

  const authorCommissions = commissionPercentages.filter(
    c => c.authorSlug === authorSlug && c.isActive
  )

  return (
    <Paper className={clsx(classes.paper, classes.tabPanelPaper)} elevation={2}>
      <Box p={4} display="flex" flexDirection="column">
        <Typography variant="h5" component="h2" gutterBottom>
          {selectedYear} Ginger GM Author Commissions
        </Typography>
        {filteredAuthors.length > 0 ? (
          <Box>
            <AuthorSelect
              className={classes.select}
              value={authorSlug}
              authors={filteredAuthors}
              handleChange={e => {
                setIsLoaded(false)
                setReportData([])
                setAuthorSlug(e.target.value)
              }}
              hideInputLabel
            />
          </Box>
        ) : (
          <Typography gutterBottom>
            No commissions found for {selectedYear}. They can be created on the Settings tab.
          </Typography>
        )}
        {!isLoaded && authorSlug !== 'select' && !error && <CircularProgress />}
        {error && (
          <Typography color="error" gutterBottom>
            {error}
          </Typography>
        )}
        {isLoaded && !error && authorSlug !== 'select' && (
          <ReportHeader
            authors={authors}
            error={error}
            isLoaded={isLoaded}
            reportData={reportData}
            authorSlug={authorSlug}
            authorCommissions={authorCommissions}
            year={selectedYear}
            month={selectedMonth}
          />
        )}
      </Box>
    </Paper>
  )
}

ReportTab.propTypes = {
  authorSlug: PropTypes.string.isRequired,
  setAuthorSlug: PropTypes.func.isRequired,
  authors: PropTypes.arrayOf(PropTypes.object).isRequired,
  commissionPercentages: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedYear: PropTypes.string.isRequired,
  selectedMonth: PropTypes.string.isRequired,
}

export default ReportTab
