import React from 'react'
import Typography from '@material-ui/core/Typography'

import Layout from '../Layout'

const Customers = () => {
  return (
    <Layout title="Customers">
      <Typography>
        This page will show customer information, including data from Drip, logins to gingergm.com
        and HelpScout conversations.
      </Typography>
    </Layout>
  )
}
export default Customers
