import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  tabs: {
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  tabPanelPaper: {
    margin: theme.spacing(2),
  },
}))

const marks = [
  {
    value: 0,
    label: '£0',
  },
  {
    value: 1,
    label: '£1',
  },
  {
    value: 2,
    label: '£2',
  },
  {
    value: 3,
    label: '£3',
  },
  {
    value: 4,
    label: '£4',
  },
  {
    value: 5,
    label: '£5',
  },
]

const SettingsTab = ({ commissionFixedGBP, setCommissionFixedGBP }) => {
  const classes = useStyles()

  return (
    <Paper className={clsx(classes.paper, classes.tabPanelPaper)} elevation={3}>
      <Box p={4}>
        <Typography id="commission-amount-slider" gutterBottom>
          Killer d4 eBook Commission amount
        </Typography>
        <Slider
          defaultValue={commissionFixedGBP}
          getAriaValueText={x => `£${x}`}
          valueLabelFormat={x => `£${x}`}
          aria-labelledby="commission-amount-slider"
          valueLabelDisplay="on"
          marks={marks}
          step={0.1}
          min={0}
          max={5}
          onChangeCommitted={(e, value) => setCommissionFixedGBP(value)}
        />
      </Box>
    </Paper>
  )
}

SettingsTab.propTypes = {
  commissionFixedGBP: PropTypes.number.isRequired,
  setCommissionFixedGBP: PropTypes.func.isRequired,
}

export default SettingsTab
