import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import uniq from 'lodash/uniq'

import Header from './Header'
import InfoButton from '../../InfoButton'
import NoMatch from '../../NoMatch'
import Layout from '../../Layout'
import ItemsTable from './ItemsTable'
import CustomerDetail from './CustomerDetail'
import Refunds from './Refunds'
import { parseError } from '../../../lib/helpers'

class InvalidTransactionError extends Error {
  constructor(params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(params)

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, InvalidTransactionError)
    }

    this.name = 'InvalidTransactionError'
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    overflow: 'auto',
    padding: theme.spacing(2),
    width: '100%',
  },
  fixedHeightPaper: {
    height: 380,
  },
}))

const TransactionDetail = ({ match }) => {
  const classes = useStyles()
  const [error, setError] = useState(false)
  const [isNotFound, setNotFound] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [transaction, setTransaction] = useState(false)

  useEffect(() => {
    let didCancel = false

    const fetchTransaction = async () => {
      try {
        const response = await axios.get(
          `/makeFoxyRequest?url=https://api.foxycart.com/stores/22071/transactions?id=${match.params.id}&zoom=receipt,items,items:item_options,items:item_category,payments,discounts,discounts:coupon,shipments,billing_addresses,custom_fields,applied_taxes,customer`
        )
        if (didCancel) return
        if (response.data.total_items < 1) {
          throw new InvalidTransactionError('Transaction not found.')
        }
        // console.log(response.data['_embedded']['fx:transactions'][0])
        setTransaction(response.data._embedded['fx:transactions'][0])
      } catch (e) {
        if (didCancel) return
        if (e instanceof InvalidTransactionError) {
          setNotFound(true)
        }
        setError(parseError(e))
      } finally {
        if (!didCancel) {
          setIsLoading(false)
        }
      }
    }

    fetchTransaction()

    return () => {
      didCancel = true
    }
  }, [match.params.id])

  if (isNotFound) return <NoMatch />

  const gatewayTypes =
    transaction && transaction._embedded['fx:payments']
      ? uniq(transaction._embedded['fx:payments'].map(p => p.gateway_type))
      : []

  return (
    <Layout
      title={
        transaction ? (
          <Typography>
            Transaction {transaction.id}
            <InfoButton
              tooltipContent={
                <>
                  <Typography color="inherit" gutterBottom>
                    Where does this data come from?
                  </Typography>
                  <Typography variant="body2">
                    The transaction data on this page comes direct from FoxyCart, so is guaranteed
                    to be correct.
                  </Typography>
                </>
              }
            />
          </Typography>
        ) : (
          ''
        )
      }
    >
      {(isLoading || error) && (
        <Paper className={classes.paper}>
          {isLoading && <CircularProgress />}
          {error && <Typography color="error">{error}</Typography>}
        </Paper>
      )}
      {!isLoading && !error && (
        <>
          <Paper className={classes.paper}>
            <Header transaction={transaction} />
          </Paper>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <Box display="flex" height="100%">
                <Paper className={classes.paper}>
                  <ItemsTable transaction={transaction} />
                </Paper>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box display="flex" height="100%">
                <Paper className={classes.paper}>
                  <Refunds transactionId={transaction.id} gatewayTypes={gatewayTypes} />
                </Paper>
              </Box>
            </Grid>
          </Grid>
          <Paper className={classes.paper}>
            <CustomerDetail transaction={transaction} />
          </Paper>
        </>
      )}
    </Layout>
  )
}

TransactionDetail.propTypes = {
  match: PropTypes.object.isRequired,
}

export default TransactionDetail
