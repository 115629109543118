import React from 'react'
import Typography from '@material-ui/core/Typography'

import Layout from '../Layout'

const Videos = () => {
  return (
    <Layout title="Videos">
      <Typography>
        This page will pull in videos + stats from Wistia. Eventually it might allow uploading and
        editing too.
      </Typography>
    </Layout>
  )
}
export default Videos
