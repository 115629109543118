import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(() => ({
  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}))

const PurchaseOptionSelect = ({ hideInputLabel, value, handleChange, purchaseOptions }) => {
  // console.log(purchaseOptions)

  const classes = useStyles()

  return (
    <>
      {!hideInputLabel && <InputLabel htmlFor="purchase-option-simple">Purchase Option</InputLabel>}
      <Select
        value={value}
        onChange={handleChange}
        inputProps={{
          name: 'purchase-option',
          id: 'purchase-option-simple',
        }}
      >
        <MenuItem value="select">Select a purchase option</MenuItem>
        {purchaseOptions.map(p => (
          <MenuItem key={p.value} value={p.value} className={classes.menuItem}>
            <Typography>{p.label}</Typography>
            <Typography variant="caption">{p.value}</Typography>
          </MenuItem>
        ))}
      </Select>
    </>
  )
}

PurchaseOptionSelect.propTypes = {
  handleChange: PropTypes.func.isRequired,
  hideInputLabel: PropTypes.bool,
  purchaseOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.string.isRequired,
}
PurchaseOptionSelect.defaultProps = {
  hideInputLabel: false,
}

export default PurchaseOptionSelect
