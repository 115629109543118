import React from 'react'
import { Text, StyleSheet, View } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  view: {
    borderTopColor: '#eee',
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    marginTop: 10,
    paddingTop: 10,
    marginHorizontal: 35,
    bottom: 30,
    color: 'grey',
    flexDirection: 'row',
    fontSize: 12,
    justifyContent: 'space-between',
    position: 'absolute',
    width: '100%',
  },
  column1: {
    flex: 1,
    textAlign: 'left',
  },
  column2: {
    flex: 1,
    textAlign: 'center',
  },
  column3: {
    flex: 1,
    textAlign: 'right',
  },
})

const PDFFooter = () => (
  <View style={styles.view} fixed>
    <Text fixed style={styles.column1}>
      https://gingergm.com
    </Text>
    <Text
      fixed // https://github.com/diegomura/react-pdf/issues/525
      style={styles.column2}
      render={({ pageNumber, totalPages }) => `Page ${pageNumber} / ${totalPages}`}
    />
    <Text fixed style={styles.column3}>
      sales@gingergm.com
    </Text>
  </View>
)

export default PDFFooter
