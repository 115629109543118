import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import format from 'date-fns/format'
import isPast from 'date-fns/isPast'
import isFuture from 'date-fns/isFuture'
import parseISO from 'date-fns/parseISO'
import CheckIcon from '@material-ui/icons/Check'
import MUILink from '@material-ui/core/Link'
import { Link } from 'react-router-dom'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import CircularProgress from '@material-ui/core/CircularProgress'
import uniq from 'lodash/uniq'
import axios from 'axios'

import { parseError } from '../../lib/helpers'
import DeleteCoupon from './DeleteCoupon'
import InfoButton from '../InfoButton'

const useStyles = makeStyles(theme => ({
  date: {
    marginRight: theme.spacing(1),
  },
}))

const CouponRow = ({ coupon: c, deleteCoupon }) => {
  const classes = useStyles()
  const [categories, setCategories] = useState(null)
  const [categoriesError, setCategoriesError] = useState(false)
  const [isCategoriesRequestInProgress, setCategoriesRequestInProgress] = useState(false)

  const fetchCategories = async () => {
    if (isCategoriesRequestInProgress) return
    setCategoriesRequestInProgress(true)
    try {
      const catPromises = c._embedded['fx:coupon_item_categories'].map(cat => {
        return axios.get(`/makeFoxyRequest?url=${cat._links['fx:item_category'].href}`)
      })
      const catResponses = await Promise.all(catPromises)
      setCategories(catResponses.map(r => r.data.name))
    } catch (e) {
      setCategoriesError(`Load categories ${parseError(e)}`)
    }
  }

  // Is the coupon valid today?
  let isCouponValidToday = true
  if (c.start_date && !isPast(parseISO(c.start_date))) isCouponValidToday = false
  if (c.end_date && !isFuture(parseISO(c.end_date))) isCouponValidToday = false

  const discountParts = c.coupon_discount_details.split('|')

  return (
    <TableRow key={c.name}>
      <TableCell>{isCouponValidToday && <CheckIcon />}</TableCell>
      <TableCell>
        <Box>
          <Typography>
            <MUILink component={Link} to={`/coupons/${c.id}`}>
              {c.name.replace('GGM::', '')}
            </MUILink>{' '}
            ({c.number_of_uses_to_date} use{c.number_of_uses_to_date !== 1 && 's'})
          </Typography>
          <Box>
            <Typography>
              {c.start_date ? (
                <span className={classes.date}>{`Start date: ${format(
                  parseISO(c.start_date),
                  'yyyy-MM-dd'
                )}`}</span>
              ) : null}
              {c.end_date ? (
                <span className={classes.date}>{`End date: ${format(
                  parseISO(c.end_date),
                  'yyyy-MM-dd'
                )}`}</span>
              ) : null}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2">
              {c._embedded && c._embedded['fx:coupon_codes']
                ? `${c._embedded['fx:coupon_codes'].length} code${
                    c._embedded['fx:coupon_codes'].length !== 1 ? 's' : ''
                  }`
                : 'No codes'}
            </Typography>
            <Typography variant="caption">
              {c.number_of_uses_allowed_per_code === 0 ? '∞' : c.number_of_uses_allowed_per_code}{' '}
              use{c.number_of_uses_allowed_per_code !== 1 && 's'} allowed per code
            </Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell align="right">
        <Typography>
          {(!c.product_code_restrictions || c.product_code_restrictions.trim() === '') && 'All'}
          {c.product_code_restrictions && c.product_code_restrictions.trim() !== '' && (
            <>
              {c.product_code_restrictions.split(',').length}
              <InfoButton
                tooltipContent={
                  <>
                    <List dense>
                      {uniq(c.product_code_restrictions.split(',')).map(cat => (
                        <ListItem key={cat}>{cat.trim()}</ListItem>
                      ))}
                    </List>
                  </>
                }
              />
            </>
          )}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography>
          {(!c._embedded || !c._embedded['fx:coupon_item_categories']) && 'All'}
          {c._embedded && c._embedded['fx:coupon_item_categories'] && (
            <>
              {c._embedded && c._embedded['fx:coupon_item_categories'].length}
              <InfoButton
                onOpen={categories ? null : fetchCategories}
                tooltipContent={
                  categoriesError ? (
                    categoriesError
                  ) : categories ? (
                    <List dense>
                      {categories.map(cat => (
                        <ListItem key={cat}>{cat}</ListItem>
                      ))}
                    </List>
                  ) : (
                    <CircularProgress variant="indeterminate" />
                  )
                }
              />
            </>
          )}
        </Typography>
      </TableCell>
      <TableCell align="right" style={{ paddingRight: 0 }}>
        <List disablePadding dense>
          {discountParts.map(p => {
            const couponPart = p.split('-')[0]
            const couponAmount = p.split('-')[1]
            return (
              <Box key={p}>
                {couponAmount && (
                  <ListItem key={p} disableGutters>
                    <ListItemText>
                      <span>
                        {parseInt(couponPart, 10) !== 0 && (
                          <span>
                            {c.coupon_discount_type.split('_')[0] === 'quantity' && 'Buy '}
                            {c.coupon_discount_type.split('_')[0] === 'price' && 'Price >= '}
                            {couponPart}
                            {couponPart && ':'}
                          </span>
                        )}
                      </span>
                    </ListItemText>
                    <ListItemSecondaryAction>
                      {c.coupon_discount_type.split('_')[1] === 'amount' && '£'}
                      {couponAmount}
                      {c.coupon_discount_type.split('_')[1] === 'percentage' && '%'}
                    </ListItemSecondaryAction>
                  </ListItem>
                )}
              </Box>
            )
          })}
        </List>
      </TableCell>
      <TableCell>
        <DeleteCoupon coupon={c} deleteCoupon={deleteCoupon} />
      </TableCell>
    </TableRow>
  )
}

CouponRow.propTypes = {
  coupon: PropTypes.object.isRequired,
  deleteCoupon: PropTypes.func.isRequired,
}

export default CouponRow
