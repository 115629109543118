import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import { withSnackbar } from 'notistack'
import AddIcon from '@material-ui/icons/Add'

import AuthorSelect from '../AuthorSelect'
import ProductSelect from '../../../ProductSelect'
import PurchaseOptionSelect from '../../../PurchaseOptionSelect'
import { parseError, GBP } from '../../../../lib/helpers'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
}))

const AddButton = ({
  enqueueSnackbar,
  setCommissionPercentages,
  year,
  products,
  authors,
  isDisabled,
  className,
}) => {
  // console.log(products)
  const classes = useStyles()
  const [isAddDialogOpen, setAddDialogOpen] = useState(false)
  const [author, setAuthor] = useState('select')
  const [product, setProduct] = useState('select')
  const [purchaseOption, setPurchaseOption] = useState('select')
  const [commissionType, setCommissionType] = useState('fixed')
  const [commissionAmount, setCommissionAmount] = useState('0')
  const [isLoaded, setLoaded] = useState(true)

  const allPurchaseOptions = products.slice()
  const purchaseOptions = []
  if (product !== 'select') {
    allPurchaseOptions.forEach(p => {
      if (!p.data.product || !p.data.product.data) return
      if (p.data.product.data.title[0].text === product) {
        // console.log(p)
        purchaseOptions.push({ value: p.uid, label: `${p.data.description} ${GBP(p.data.price)}` })
      }
    })
  }

  const handleAddDialogOpen = () => setAddDialogOpen(true)
  const handleAddDialogClose = () => {
    if (!isLoaded) return
    setAuthor('select')
    setProduct('select')
    setPurchaseOption('select')
    setCommissionType('percentage')
    setCommissionAmount('0')
    setAddDialogOpen(false)
  }
  const handleAuthorChange = e => setAuthor(e.target.value)
  const handleProductChange = e => {
    setProduct(e.target.value)
    setPurchaseOption('select')
  }
  const handlePurchaseOptionChange = e => setPurchaseOption(e.target.value)
  const handleCommissionTypeChange = e => setCommissionType(e.target.value)
  const handleCommissionAmountChange = e => setCommissionAmount(e.target.value)

  const handleAddCommission = async () => {
    if (
      author === 'select' ||
      purchaseOption === 'select' ||
      parseFloat(commissionAmount) <= 0 ||
      commissionAmount === ''
    ) {
      return
    }

    try {
      setLoaded(false)
      const response = await axios.get(
        `/commissionsReportSettings?action=add&year=${year}&authorSlug=${author}&purchaseOption=${purchaseOption}&type=${commissionType}&amount=${commissionAmount}`
      )
      // console.log(response.data)
      if (response.data.count !== 1) {
        enqueueSnackbar(`Unable to add commission.`)
      } else {
        const response = await axios.get(`/commissionsReportSettings?year=${year}`)
        setCommissionPercentages(response.data)
        enqueueSnackbar('Commission added.', { variant: 'success' })
      }
    } catch (e) {
      enqueueSnackbar(parseError(e), { variant: 'error' })
    } finally {
      handleAddDialogClose()
      setAuthor('select')
      setProduct('select')
      setPurchaseOption('select')
      setCommissionType('percentage')
      setCommissionAmount('0')
      setLoaded(true)
    }
  }

  return (
    <>
      <Button
        className={className}
        variant="contained"
        color="secondary"
        onClick={handleAddDialogOpen}
        disabled={isDisabled}
      >
        Add commission
        <AddIcon className={classes.rightIcon} />
      </Button>
      <Dialog
        fullWidth
        open={isAddDialogOpen}
        onClose={handleAddDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add a commission</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column">
            <FormControl className={classes.formControl}>
              <AuthorSelect value={author} handleChange={handleAuthorChange} authors={authors} />
            </FormControl>
            {author !== 'select' && (
              <FormControl className={classes.formControl}>
                <ProductSelect
                  products={products}
                  value={product}
                  handleChange={handleProductChange}
                />
              </FormControl>
            )}
            {product !== 'select' && author !== 'select' && (
              <>
                <FormControl className={classes.formControl}>
                  <PurchaseOptionSelect
                    value={purchaseOption}
                    handleChange={handlePurchaseOptionChange}
                    purchaseOptions={purchaseOptions}
                  />
                </FormControl>
                {purchaseOption !== 'select' && (
                  <Box display="flex" justifyContent="space-between">
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="commission-type-simple">Commission Type</InputLabel>
                      <Select
                        style={{ minWidth: 180 }}
                        value={commissionType}
                        onChange={handleCommissionTypeChange}
                        inputProps={{
                          name: 'commission-type-option',
                          id: 'commission-type-simple',
                        }}
                      >
                        <MenuItem value="fixed">Fixed amount (GBP)</MenuItem>
                        <MenuItem value="percentage">Percentage</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <TextField
                        inputProps={{
                          min: 0,
                          step: 0.5,
                        }}
                        label="Amount"
                        type="number"
                        value={commissionAmount}
                        onChange={handleCommissionAmountChange}
                      />
                    </FormControl>
                  </Box>
                )}
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddDialogClose} disabled={!isLoaded}>
            Cancel
          </Button>
          <Button
            onClick={handleAddCommission}
            color="primary"
            disabled={
              !isLoaded ||
              author === 'select' ||
              purchaseOption === 'select' ||
              parseFloat(commissionAmount) <= 0 ||
              commissionAmount === ''
            }
          >
            Add commission
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

AddButton.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  authors: PropTypes.arrayOf(PropTypes.object).isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  setCommissionPercentages: PropTypes.func.isRequired,
  year: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
}
AddButton.defaultProps = {
  isDisabled: false,
  className: null,
}

export default withSnackbar(AddButton)
