import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import MUILink from '@material-ui/core/Link'
import { CSVLink } from 'react-csv'
import { PDFViewer } from '@react-pdf/renderer'

import PDFReport from './PDFReport'
import MonthSelect from '../../MonthSelect'
import { GBP } from '../../../../lib/helpers'

const useStyles = makeStyles(theme => ({
  actionLink: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

const ReportTab = ({
  selectedMonth,
  setMonth,
  isLoaded,
  error,
  reportData,
  commissionPercentage,
}) => {
  const classes = useStyles()

  // console.log(reportData)
  const csvData = reportData.map(item => {
    const totalDiscount = item.discounts.map(d => d.amount).reduce((sum, d) => sum + d, 0)

    return {
      transaction_id: item.id,
      transaction_date: item.transaction_date,
      product: item.name,
      selection: item.selection,
      sku: item.code,
      price_gbp: GBP(item.price, { formatWithSymbol: false }),
      quantity: item.quantity,
      coupons: item.discounts.map(d => d.coupon).join(', '),
      total_discount_gbp: GBP(totalDiscount, { formatWithSymbol: false }),
      sale_price_gbp: GBP(item.price * item.quantity + totalDiscount, {
        formatWithSymbol: false,
      }),
      commission_percentage: commissionPercentage,
      commission_amount: GBP(
        ((item.price * item.quantity + totalDiscount) * commissionPercentage) / 100,
        { formatWithSymbol: false }
      ),
    }
  })

  return (
    <>
      <Box display="flex" justifyContent="center" mb={4}>
        <MonthSelect value={selectedMonth} handleChange={e => setMonth(e.target.value)} />
      </Box>

      {!isLoaded && <CircularProgress />}
      {error && <Typography color="error">{error}</Typography>}
      {!error && isLoaded && (
        <Box display="flex" flexDirection="column" alignItems="center" width="100%">
          <MUILink
            component={CSVLink}
            data={csvData}
            filename={`ichess-commissions-${selectedMonth}.csv`}
            className={classes.actionLink}
            variant="body1"
          >
            Download CSV file
          </MUILink>
          <PDFViewer height={800} width="100%">
            <PDFReport
              reportData={reportData}
              commissionPercentage={commissionPercentage}
              selectedMonth={selectedMonth}
            />
          </PDFViewer>
        </Box>
      )}
    </>
  )
}

ReportTab.propTypes = {
  commissionPercentage: PropTypes.number.isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  isLoaded: PropTypes.bool.isRequired,
  reportData: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedMonth: PropTypes.string.isRequired,
  setMonth: PropTypes.func.isRequired,
}

export default ReportTab
