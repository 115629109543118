import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormHelperText from '@material-ui/core/FormHelperText'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
import axios from 'axios'

import Layout from '../Layout'
import TransactionsTable from './TransactionsTable'
import { parseError, isNumeric } from '../../lib/helpers'

const useStyles = makeStyles(theme => ({
  formControl: {
    flexBasis: 340,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}))

const Transactions = () => {
  const classes = useStyles()
  const [url, setUrl] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [hasSearched, setHasSearched] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [transactions, setTransactions] = useState([])
  const [error, setError] = useState(false)
  const firstUpdate = useRef(true)

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    let didCancel = false

    const doSearch = async () => {
      setError(false)
      setHasSearched(true)
      setIsSearching(true)

      try {
        const response = await axios.get(url)
        // console.log(response.data)
        if (didCancel) return
        setTransactions(response.data['_embedded']['fx:transactions'])
      } catch (e) {
        if (didCancel) return
        setError(parseError(e))
      } finally {
        if (!didCancel) {
          setIsSearching(false)
        }
      }
    }

    doSearch()

    return () => {
      didCancel = true
    }
  }, [url])

  const buildUrl = () => {
    let baseUrl = `/makeFoxyRequest?url=https://api.foxycart.com/stores/22071/transactions?zoom=receipt,items,items:item_options,items:item_category,payments,discounts,discounts:coupon,shipments,billing_addresses,custom_fields,applied_taxes,customer`

    if (isNumeric(searchValue.trim())) {
      return `${baseUrl}&id=${searchValue}`
    } else {
      return `${baseUrl}&customer_email=${searchValue}`
    }
  }

  const handleClickSearch = () => {
    if (searchValue.trim().length === 0) return
    setUrl(buildUrl())
  }

  const handleMouseDownSearch = event => {
    event.preventDefault()
  }

  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      setUrl(buildUrl())
    }
  }

  return (
    <Layout title="Transactions">
      <Box display="flex" justifyContent="center" mb={4}>
        <FormControl className={classes.formControl} variant="outlined">
          <InputLabel htmlFor="search">Search transactions</InputLabel>
          <OutlinedInput
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="search"
                  onClick={handleClickSearch}
                  onMouseDown={handleMouseDownSearch}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
            id="search"
            labelWidth={145}
            onChange={e => setSearchValue(e.target.value)}
            onKeyDown={handleKeyDown}
            value={searchValue}
          />
          <FormHelperText>Transaction ID or Email</FormHelperText>
        </FormControl>
      </Box>
      {isSearching && <CircularProgress />}
      {error && <Typography color="error">{error}</Typography>}
      {hasSearched && !isSearching && (
        <Paper className={classes.paper}>
          {transactions.length > 0 ? (
            <TransactionsTable transactions={transactions} />
          ) : (
            <Typography>No results.</Typography>
          )}
        </Paper>
      )}
    </Layout>
  )
}

export default Transactions
