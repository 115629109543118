import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'

import AlertDialog from '../AlertDialog'

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 700,
  },
}))

const DeleteCoupon = ({ coupon: c, deleteCoupon }) => {
  const classes = useStyles()
  const [isConfirmDeleteOpen, setConfirmDeleteOpen] = useState(false)

  return (
    <>
      <IconButton onClick={() => setConfirmDeleteOpen(true)}>
        <DeleteIcon />
      </IconButton>
      <AlertDialog
        key={c.code}
        dialogContent={
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete the coupon{' '}
              <span className={classes.bold}>{c.name.replace('GGM::', '')}</span>? The coupon's
              usage statistics will be lost.
            </DialogContentText>
          </DialogContent>
        }
        dialogTitle="Delete coupon"
        handleClose={() => setConfirmDeleteOpen(false)}
        handleOk={() => deleteCoupon(c._links.self.href)}
        isOpen={isConfirmDeleteOpen}
      />
    </>
  )
}

DeleteCoupon.propTypes = {
  coupon: PropTypes.object.isRequired,
  deleteCoupon: PropTypes.func.isRequired,
}

export default DeleteCoupon
