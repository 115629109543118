import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import MUILink from '@material-ui/core/Link'
import { CSVLink } from 'react-csv'
import { PDFViewer } from '@react-pdf/renderer'
import { KeyboardDatePicker } from '@material-ui/pickers'
import format from 'date-fns/format'
import isValid from 'date-fns/isValid'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'

import PDFReport from './PDFReport'
import { GBP } from '../../../../lib/helpers'

const useStyles = makeStyles(theme => ({
  actionLink: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  datePicker: {
    marginRight: theme.spacing(2),
  },
}))

const sumCommissionItems = (data, commissions) => {
  return data.reduce((sum, item) => {
    const commission = commissions.find(c => c.purchaseOption === item.code)
    if (!commission) return sum

    const discountTotal = item.discounts ? item.discounts.reduce((sum, d) => sum + d.amount, 0) : 0
    const discountedPrice = item.price * item.quantity + discountTotal
    const discountPercentage =
      item.price * item.quantity > 0 ? discountedPrice / (item.price * item.quantity) : 0

    if (commission.commissionFixedGBP) {
      return sum + commission.commissionFixedGBP * item.quantity * discountPercentage
    }
    if (commission.commissionPercentage) {
      return sum + (commission.commissionPercentage * discountedPrice) / 100
    }

    return 0
  }, 0)
}

const ReportTab = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  isLoaded,
  error,
  reportData,
  commissionFixedGBP,
}) => {
  // console.log(commissionFixedGBP)
  const classes = useStyles()

  const authorCommissions = [
    { purchaseOption: 'ebook_killer_d4_1', commissionFixedGBP },
    { purchaseOption: 'ebook_killer_d4_2', commissionFixedGBP },
  ]

  const byProduct = groupBy(reportData, 'code')
  const productTotals = sortBy(
    Object.keys(byProduct).map(p => {
      const commissionDetailsObj = authorCommissions.find(c => c.purchaseOption === p)
      const commissionDetails = `Fixed ${GBP(commissionDetailsObj.commissionFixedGBP)}`

      return {
        code: p,
        count: byProduct[p].reduce((sum, t) => sum + t.quantity, 0),
        commissionDetails,
        name: byProduct[p][0].name,
        selection: byProduct[p][0].selection,
        total: sumCommissionItems(byProduct[p], authorCommissions),
      }
    }),
    'total'
  )
    .filter(p => p.total > 0)
    .reverse()
  // console.log(productTotals)

  // console.log(reportData)
  const csvData = reportData.map(item => {
    const discountTotal = item.discounts ? item.discounts.reduce((sum, d) => sum + d.amount, 0) : 0
    const discountedPrice = item.price * item.quantity + discountTotal
    const discountPercentage =
      item.price * item.quantity > 0 ? discountedPrice / (item.price * item.quantity) : 0

    const commission_value = commissionFixedGBP * item.quantity * discountPercentage

    return {
      transaction_id: item.id,
      transaction_date: item.transaction_date,
      product: item.name,
      selection: item.selection,
      sku: item.code,
      price_gbp: GBP(item.price, { formatWithSymbol: false }),
      quantity: item.quantity,
      coupons: item.discounts.map(d => d.coupon).join(', '),
      total_discount_gbp: GBP(discountTotal, { formatWithSymbol: false }),
      sale_price_gbp: GBP(item.price * item.quantity + discountTotal, {
        formatWithSymbol: false,
      }),
      commission_amount: commissionFixedGBP,
      commission_value,
    }
  })

  return (
    <>
      <Box display="flex" justifyContent="center" mb={4}>
        <KeyboardDatePicker
          className={classes.datePicker}
          disabled={!isLoaded}
          disableToolbar
          variant="inline"
          format="yyyy-MM-dd"
          margin="normal"
          id="start-date"
          label="Start date"
          value={startDate}
          onChange={d => {
            if (!isValid(d)) return
            setStartDate(format(d, 'yyyy-MM-dd'))
          }}
          KeyboardButtonProps={{
            'aria-label': 'change start date',
          }}
        />
        <KeyboardDatePicker
          className={classes.datePicker}
          disabled={!isLoaded}
          disableToolbar
          variant="inline"
          format="yyyy-MM-dd"
          margin="normal"
          id="end-date"
          label="End date"
          value={endDate}
          onChange={d => {
            if (!isValid(d)) return
            setEndDate(format(d, 'yyyy-MM-dd'))
          }}
          KeyboardButtonProps={{
            'aria-label': 'change end date',
          }}
        />
      </Box>

      {!isLoaded && <CircularProgress />}
      {error && <Typography color="error">{error}</Typography>}
      {!error && isLoaded && (
        <Box display="flex" flexDirection="column" alignItems="center" width="100%">
          <MUILink
            component={CSVLink}
            data={csvData}
            filename={`killer-d4-ebook-commissions-${startDate}-${endDate}.csv`}
            className={classes.actionLink}
            variant="body1"
          >
            Download CSV file
          </MUILink>
          <PDFViewer height={800} width="100%">
            <PDFReport
              reportData={productTotals}
              commissionFixedGBP={commissionFixedGBP}
              startDate={startDate}
              endDate={endDate}
            />
          </PDFViewer>
        </Box>
      )}
    </>
  )
}

ReportTab.propTypes = {
  commissionFixedGBP: PropTypes.number.isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  isLoaded: PropTypes.bool.isRequired,
  reportData: PropTypes.arrayOf(PropTypes.object).isRequired,
  startDate: PropTypes.string.isRequired,
  setStartDate: PropTypes.func.isRequired,
  endDate: PropTypes.string.isRequired,
  setEndDate: PropTypes.func.isRequired,
}

export default ReportTab
