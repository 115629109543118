import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import clsx from 'clsx'
import { grey } from '@material-ui/core/colors'

import ExternalLink from '../../../ExternalLink'
import { GBP, getItemCategoryText } from '../../../../lib/helpers'

const useStyles = makeStyles(theme => ({
  cell: {
    borderBottom: 'none',
    paddingLeft: 0,
  },
  itemCell: {
    width: 420,
  },
  priceCell: {
    minWidth: 160,
  },
  discountCell: {
    width: 300,
  },
  image: {
    border: `1px solid ${grey[100]}`,
    margin: theme.spacing(1),
    width: 80,
  },
}))

const ItemDetail = ({ item: i, hiddenColumns, discounts, allOrderItems }) => {
  const classes = useStyles()

  return (
    <TableRow key={i.code}>
      <TableCell className={clsx(classes.cell, classes.itemCell)}>
        <Box display="flex" alignItems="center">
          <Link href={i.url} target="_blank">
            <img alt={i.name} src={i.image} className={classes.image} />
          </Link>
          <div>
            <ExternalLink
              href={i.url}
              linkText={`
                ${
                  i._embedded['fx:item_category']
                    ? getItemCategoryText(i._embedded['fx:item_category'].code) + ' '
                    : ''
                }${i.name}`}
            />
            {i._embedded['fx:item_options'] &&
              i._embedded['fx:item_options'].map(o => {
                // console.log(o)
                switch (o.name) {
                  case 'selection':
                    return (
                      <Typography variant="body2" key={o.name}>
                        Selection: {o.value}
                      </Typography>
                    )
                  default:
                    return null
                }
              })}
            <Typography variant="body2">SKU: {i.code}</Typography>
          </div>
        </Box>
      </TableCell>

      {!hiddenColumns.includes('shipping') && (
        <TableCell className={classes.cell} align="right">
          {i.weight > 0 && <LocalShippingIcon />}
        </TableCell>
      )}

      <TableCell className={clsx(classes.cell, classes.priceCell)} align="right">
        {i.quantity > 1 && `(${i.quantity}) `}
        {GBP(i.price * i.quantity)}
      </TableCell>
    </TableRow>
  )
}

ItemDetail.propTypes = {
  allOrderItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  discounts: PropTypes.arrayOf(PropTypes.object),
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
  item: PropTypes.object.isRequired,
}
ItemDetail.defaultProps = {
  allOrderItems: [],
  discounts: [],
  hiddenColumns: [],
}

export default ItemDetail
